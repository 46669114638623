import { Add, Chat, PhoneFilled } from "@carbon/icons-react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChatbotService } from "./ChatbotService";

export const getServiceList = createAsyncThunk(
  "chatbot/getServiceList",
  async (_, thunkAPI) => {
    const { subscriptions } = thunkAPI.getState();

    if (subscriptions.data?.length) {
      const uniqueSubs = new Set(
        subscriptions?.data.map(
          (subscription) => subscription.subscription.subscription_name
        )
      );

      const subs = uniqueSubs.map((sub) => ({ product_name: sub }));

      return {
        plan_list: subs,
      };
    }

    return await ChatbotService.getServiceList().catch(
      thunkAPI.rejectWithValue
    );
  }
);

export const getServiceIssue = createAsyncThunk(
  "chatbot/getServiceIssue",
  async (payload, thunkAPI) => {
    const data = await ChatbotService.getServiceIssue(payload.text).catch(
      thunkAPI.rejectWithValue
    );

    return {
      data,
      service: payload,
    };
  }
);

export const getFaqCombineData = createAsyncThunk(
  "chatbot/getTotalFaqDatacombine",
  async (payload, thunkAPI) => {
    try {
      const data = await ChatbotService.getTotalFaqData(payload.text);
      return {
        data: data,
        text: payload.text,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getChat = createAsyncThunk(
  "chatbot/getChat",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState().chatbot;

    let prompt = payload.original_text ?? payload.text;

    if (state.selectedService) {
      prompt = prompt ?? state.selectedService.heading;
    }

    payload.original_text = prompt;

    try {
      const data = await ChatbotService.getChat(prompt);
      return { data, payload };
    } catch (error) {
      throw error;
    }
  }
);

export const getFaq = createAsyncThunk(
  "chatbot/getFaq",
  async (payload, thunkAPI) => {
    const data = await ChatbotService.getFaq(payload?.text ?? "").catch(
      thunkAPI.rejectWithValue
    );
    return data;
  }
);

const initialState = {
  services: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },

  sidebarContent: null,

  isLoading: false,
  otherIsLoading: false,

  messages: [],

  isChatWindowVisible: false,
  fullView: false,
  toggleIcon: false,

  selectedService: null,
  faqData12: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },

  otherServices: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },
  otherProducts: {
    error: null,
    status: "idle",
    data: [],
    message: "",
  },

  otherResources: [],
};

export const ChatbotSlice = createSlice({
  name: "chatbot",
  initialState,

  reducers: {
    showServiceOptions(state, { payload }) {
      state.messages = [
        {
          from: "bot",
          text: `You selected ${payload.text}`,
          options: [
            {
              text: (
                <a
                  className="dark:!text-[#2570FE]"
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    padding: "5px",
                  }}
                  renderIcon={Add}
                >
                  <Add size={16} />
                  Create Ticket
                </a>
              ),
              from: "bot",
              parent: payload,
              action: "open_ticket",
            },
            {
              text: (
                <a
                  className="dark:!text-[#2570FE]"
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    padding: "5px",
                  }}
                  renderIcon={PhoneFilled}
                >
                  <PhoneFilled size={16} />
                  Call
                </a>
              ),
              from: "bot",
              parent: payload,
              action: "call",
            },
            {
              text: (
                <a
                  className="dark:!text-[#2570FE]"
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    padding: "5px",
                  }}
                  renderIcon={Chat}
                >
                  <Chat size={16} />
                  Chat
                </a>
              ),
              from: "bot",
              parent: payload,
              action: "chat",
            },
            {
              text: <a className="dark:!text-[#2570FE]"> Go Back</a>,
              from: "bot",
              parent: payload,
              action: "get_service_list",
            },
            {
              text: <a className="dark:!text-[#2570FE]"> FAQ ?</a>,
              from: "bot",
              parent: payload,
              action: "get_faq_list",
            },
          ],
        },
      ];
    },

    addMessage(state, { payload }) {
      state.messages.push({
        from: payload?.from ?? "me",
        text: payload?.text,
        rawHTML: payload.rawHTML,
      });

      state.isChatWindowVisible = true;
    },

    setMessage(state, { payload }) {
      state.messages = [
        {
          from: payload?.from ?? "me",
          text: payload?.text,
        },
      ];
      state.isChatWindowVisible = true;
    },

    setIsChatWindowVisibleDispatcher(state, { payload }) {
      state.isChatWindowVisible = payload;
    },

    setFullViewDispatcher(state, { payload }) {
      state.fullView = payload;
    },

    setToggleIconDispatcher(state, { payload }) {
      state.toggleIcon = payload;
    },

    setSelectedService(state, { payload }) {
      state.selectedService = payload;
    },

    setSidebarcontent(state, { payload }) {
      state.sidebarContent = payload;
    },
  },

  extraReducers: (builder) => {
    // handle get service / product FAQ
    builder.addCase(getFaqCombineData.fulfilled, (state, { payload, meta }) => {
      if (payload?.data?.data?.data?.question_and_answer?.length) {
        state.sidebarContent = {
          type: "faq",
          content: payload?.data?.data?.data?.question_and_answer?.map(
            (faq, id) => ({
              text: payload.text,
              original_text: faq,
              from: "bot",
              action: "get_faq",
              key: payload.text?.replace(/ /gi, ""),
            })
          ),
          service: meta?.arg?.text,
          serviceImage: meta?.arg?.featured_image,
        };
        state.otherResources = payload?.data?.data?.data?.resources_data;
      } else {
        state.messages.push({
          from: "bot",
          text: "No FAQ found",
          style: "error",
        });
      }
      state.isLoading = false;
    });

    builder.addCase(getFaqCombineData.pending, (state, payload) => {
      state.isLoading = true;
    });

    builder.addCase(getFaqCombineData.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    // handle get service / product
    builder.addCase(getServiceList.fulfilled, (state, { payload }) => {
      state.services.error = null;
      state.services.status = "succeeded";

      state.messages = [
        {
          from: "bot",
          text: (
            <spn className="dark:!text-white">
              Select a service / product you have issue with
            </spn>
          ),
          options: payload.data.plan_list.map((list) => {
            if ("product_name" in list) {
              return {
                text: list.product_name,
                action: "get_service_opts",
                from: "bot",
              };
            }

            if ("service_name" in list) {
              return {
                text: list.service_name,
                action: "get_service_opts",
                from: "bot",
              };
            }
          }),
        },
      ];
    });

    builder.addCase(getServiceList.pending, (state, payload) => {
      state.services.status = "loading";
      state.services.data = initialState.services.data;
      state.services.error = null;
    });

    builder.addCase(getServiceList.rejected, (state, { payload }) => {
      state.services.status = "failed";
      state.services.data = initialState.services.data;
      state.services.error = payload;
    });

    // handle after service / product selection
    builder.addCase(getServiceIssue.fulfilled, (state, { payload, meta }) => {
      if (payload.data.length) {
        state.sidebarContent = {
          type: "faq",
          content: payload.data.map((faq, id) => ({
            text: faq.replace(`${payload.service.text}:`, "").trim(),
            original_text: faq,
            from: "bot",
            action: "get_faq",
            key: faq.replace(/ /gi, ""),
          })),
          service: meta.arg,
        };
      } else {
        state.messages.push({
          from: "bot",
          text: "No FAQ found",
          style: "error",
        });
      }

      state.isLoading = false;
    });

    builder.addCase(getServiceIssue.pending, (state, payload) => {
      state.isLoading = true;
    });

    builder.addCase(getServiceIssue.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    // handle faq
    builder.addCase(getFaq.fulfilled, (state, { payload }) => {
      state.messages.push({
        from: "bot",
        text: `<span> ${payload.botReply.replaceAll("\n", "<br />")} </span>`,
        rawHTML: true,
      });
      state.isLoading = false;
    });

    builder.addCase(getFaq.pending, (state, payload) => {
      state.isLoading = true;
    });

    builder.addCase(getFaq.rejected, (state, { payload }) => {
      state.isLoading = false;

      state.messages.push({
        from: "bot",
        text: `I'm sorry, but I'm not sure what you're asking. Could you please provide more details? I'm here to assist with any questions or concerns you may have regarding Uvation’s products and services.`,
        // style: "error",
      });
    });

    // handle chat messages
    builder.addCase(
      getChat.fulfilled,
      (state, { payload: { data, payload } }) => {
        console.log("data", data);
        state.sidebarContent = {
          type: "markdown",
          content: data.botReply,
        };

        state.isLoading = false;

        state.messages.push({
          text: `<p> We've got you covered when it comes to <a href="#" data-action="chat" data-prompt="${payload.original_text}" >${payload.text}?</a>. Our repository of information is ready to address your inquiries and offer support.</p>`,
          rawHTML: true,
          from: "bot",
        });
      }
    );

    builder.addCase(getChat.pending, (state, payload) => {
      state.isLoading = true;
    });

    builder.addCase(getChat.rejected, (state, { payload }) => {
      state.isLoading = false;

      state.messages.push({
        from: "bot",
        text: `I'm sorry, but I'm not sure what you're asking. Could you please provide more details? I'm here to assist with any questions or concerns you may have regarding Uvation’s products and services.`,
      });
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  showServiceOptions,
  addMessage,
  setMessage,
  setIsChatWindowVisibleDispatcher,
  setFullViewDispatcher,
  setToggleIconDispatcher,
  setSelectedService,
  setSidebarcontent,
} = ChatbotSlice.actions;

export default ChatbotSlice.reducer;
