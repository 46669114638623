import { SendFilled } from "@carbon/icons-react";
import { GlobalContext } from "ContextApi/GlobalContext";
import CustomButton from "components/button/customButton";
import useWindowSize from "hooks/useWindowSize";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFullViewDispatcher,
  setIsChatWindowVisibleDispatcher,
  setMessage,
  setToggleIconDispatcher,
} from "redux/slices/Chatbot/ChatbotSlice";
import ChatComponent from "./ChatComponent";
import { CopilotContent, CopilotWrapper } from "./style";

function Copilot() {
  const { isChatWindowVisible, fullView, toggleIcon } = useSelector(
    (state) => state.chatbot
  );
  const state = useSelector((state) => state.chatbot);
  const isLoading = state.isLoading || state.services.status === "loading";
  const dispatch = useDispatch();

  function setIsChatWindowVisible(payload) {
    dispatch(setIsChatWindowVisibleDispatcher(payload));

    if (payload) {
      dispatch(
        setMessage({
          text: "Hello! I'm your virtual Help Center assistant. I'm here to help you find the answers and resources you need. How can I help?",
          from: "bot",
        })
      );
    }
  }

  function setFullView(payload) {
    dispatch(setFullViewDispatcher(payload));
  }

  function setToggleIcon(payload) {
    dispatch(setToggleIconDispatcher(payload));
  }

  const { width } = useWindowSize();

  const globalContext = useContext(GlobalContext);

  const isDarkTheme = useMemo(
    () => globalContext.globalTheme === "g100",
    [globalContext.globalTheme]
  );

  if (!isChatWindowVisible) {
    return (
      <CopilotWrapper
        isLoading={isLoading}
        isChatWindowVisible={isChatWindowVisible}
        className="dark:!border-[#474747] chatbottheem"
        isDarkTheme={isDarkTheme}
        style={{
          maxHeight: fullView && width <= 768 ? "104px" : "700px",
          minHeight: width > 768 && "700px",
          display: width > 768 && "flex",
          justifyContent: "center",
        }}
      >
        <CopilotContent className="dark:!border-[#474747] chatbottheem">
          <div className="main_div">
            <img
              src={`${process.env.REACT_APP_IMAGE_URL}/Vertical.png`}
              // src="/Vertical.png"
              width={width <= 768 ? "88px" : "254px"}
              height={width <= 768 ? "50px" : "84px"}
              alt="Vertical"
            />
            {width > 768 && (
              <p className="!text-[white]  ">
                AI Copilot is your essential companion <br />
                for finding assistance and resources <br />
                related to products and services.
              </p>
            )}

            <div className="button_wrapper">
              <CustomButton
                style={{
                  minWidth: width <= 768 ? "226px" : "263px",
                  width: "100%",
                }}
                text="Chat Now"
                icon={SendFilled}
                handleClick={() => {
                  setIsChatWindowVisible(true);
                  width <= 768 && setFullView(true);
                }}
              />
            </div>
          </div>
        </CopilotContent>
      </CopilotWrapper>
    );
  }

  return (
    <CopilotWrapper
      isLoading={isLoading}
      style={{
        border:
          !isLoading && !isDarkTheme
            ? "2px solid var(--cta-cta-background-01, #E9F1FF)"
            : !isLoading && isDarkTheme
            ? "2px solid var(--cta-cta-background-01, #474747)"
            : "none",
        maxHeight:
          fullView && width <= 768 ? "457px" : width > 768 ? "700px" : "104px",
      }}
    >
      <ChatComponent
        fullView={fullView}
        setFullView={setFullView}
        setToggleIcon={setToggleIcon}
        toggleIcon={toggleIcon}
      />
    </CopilotWrapper>
  );
}

export default Copilot;
