import styled from "styled-components";

export const TicketStyle = styled.section`
  margin-top: 3rem;
  background-color: #f4f4f4;
  position: relative;

  .dropdownsticky {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
    z-index: 99;
  }

  @media screen and (max-width: 768px) {
    .cds--col-lg-10 {
      padding-inline: 0 !important;
    }
  }

  .cds--row {
    margin-inline: 0 !important;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .scroll-spy-side-nav-col {
      padding: 1rem;
      margin-left: 1rem;
    }
  }

  .container {
    padding: 32px 0;
  }

  @media screen and (max-width: 767px) {
    .container {
      padding: 0 !important;
    }
  }

  .dropdown_request {
    display: none;

    .active .spy-box-content {
      background: unset;
      border: unset;
      display: flex;
      gap: 24px;
    }

    @media screen and (max-width: 768px) {
      display: block;
      background-color: #f4f4f420;

      .cds--dropdown {
        /* z-index: 999; */
        background-color: ${(props) =>
          props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};

        .cds--list-box__field {
          background-color: #f4f4f420;

          .cds--list-box__label {
            width: 285px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-family: "IBM Plex Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.16px;
            .icon_div {
              svg {
                fill: ${(props) =>
                  props.theme.colorScheme == "white" ? "black" : "white"};
              }
            }
          }

          .cds--list-box__menu-icon {
            z-index: 25;
          }
        }
      }
    }
  }

  .mobile_slink_dropdown {
    color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    .spy-box-content {
      padding-right: 50px;
    }
  }

  .scroll-spy-link-box_mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .maindiv_create {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: 768px) {
      position: relative;
      margin-left: 0px;
      padding: 32px 0 24px 0;

      .cds--row {
        margin-inline: 0px;
      }
    }

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      margin-left: 0;
      padding-left: 1rem;
    }

    .cds--row {
      margin-inline: 0;
    }

    .service_create {
      z-index: 1;
      display: flex;
      .breadcrumb {
        z-index: 20;
      }
      span {
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
        z-index: 3;
      }
    }

    &::after {
      content: url(${process.env.REACT_APP_IMAGE_URL}/requestbackgound.png);
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 99;

      @media screen and (max-width: 479px) {
        content: url(${process.env
          .REACT_APP_IMAGE_URL}/mobilebackgroundgraphic.png);
        top: 0;
        right: -16px;
      }
    }
  }
  .mainheading {
    z-index: 20;
    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      font-style: normal;
      line-height: 36px;

      @media screen and (max-width: 768px) {
        line-height: 44px;
      }
    }
  }
  .scroll-spy-link-box {
    @media screen and (max-width: 768px) {
      display: none;
    }

    .spy-box-content {
      border-left: 2px solid var(--border-border-subtle-01, #c6c6c6);
      display: flex;
      padding: 7px 16px;
      align-items: center;
      gap: 24px;
      align-self: stretch;
      overflow: hidden;
      color: var(--text-text-secondary, var(--text-text-secondary, #525252));
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }
  .active .spy-box-content {
    background: ${(props) =>
      props.theme.colorScheme === "white" ? "#e0e0e0" : "#262626"};
    border-left: 2px solid #0f61fd;
    color: #161616;
    display: flex;
    gap: 24px;
  }
`;
