import { useAccount, useMsal } from "@azure/msal-react";
import { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const defaultTheme = localStorage.getItem("theme") || "white";
  const [globalTheme, setGlobalTheme] = useState(defaultTheme);

  const [toggle, settoggle] = useState({
    Quick: false,
    Support: false,
    Notification: false,
    Account: false,
    openShow: "",
    closedShow: "",
    pendingShow: "",
    newShow: "",
    tabselect: "",
    FillterToggle: false,
    ListActive: false,
    viewSummary: false,
  });

  const [ticket_id, setticket_id] = useState(null);

  useEffect(() => {
    if (account) {
      localStorage.setItem("supportusermail", account.idTokenClaims?.email);
      localStorage.setItem(
        "supportusername",
        account.idTokenClaims?.given_name
      );
      localStorage.setItem("newuser_d", account.idTokenClaims?.newUser);
    }
  }, [account]);

  return (
    <GlobalContext.Provider
      value={{
        globalTheme,
        setGlobalTheme,
        toggle,
        settoggle,
        ticket_id,
        setticket_id,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
