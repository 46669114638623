import {
  Add,
  Filter,
  CaretDown,
  Launch,
  RequestQuote,
  ChevronDown,
  NotificationOff,
} from "@carbon/icons-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  Column,
  DataTable,
  DataTableSkeleton,
  DatePicker,
  DatePickerInput,
  FilterableMultiSelect,
  FlexGrid,
  MultiSelect,
  Pagination,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from "@carbon/react";
import { Contextprovider } from "App";
import { Serivity, caseType, items } from "Component/Data/DataJson";
import ServiceRequestStatus from "components/ServiceRequestStatus";
import CustomButton from "components/button/customButton";
import NoDataInTable from "components/datatable/noDataInTable";
import { Formik } from "formik";
import useWindowSize from "hooks/useWindowSize";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getSupportTickets } from "redux/slices/Support/SupportSlice";
import { GlobalContext } from "ContextApi/GlobalContext";
import * as Yup from "yup";
import RequestWrapper from "./style";
import { getServiceRequestStatusText } from "utils";
import { handleActiveIndex, headerState } from "redux/slices/headerSlice";
import { handleTicketFilter, filterState } from "redux/slices/TicketSlice";

export const Requesheader = [
  {
    header: "Request #",
    key: "id",
  },
  {
    header: "Date",
    key: "created_at",
  },
  {
    header: "Case Type",
    key: "casetype",
  },
  {
    header: "Subject",
    key: "subject",
  },

  {
    header: "Severity",
    key: "servity",
  },
  {
    header: "Status",
    key: "status",
  },
];

const Request = () => {
  const [filterBtn, setFilterBtn] = useState(false);
  const [activePage, setActivePage] = useState({ page: 1, pageSize: 10 });
  const { useralldata } = useContext(Contextprovider);
  const TicketFilter = useSelector((state) => state.filter.TicketFilter);

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const context = useContext(GlobalContext);

  const {
    tickets: { data: tickets },
    tickets: { count },
  } = useSelector((state) => state.support);

  const {
    tickets: { status: loading },
  } = useSelector((state) => state.support);

  const [filters, setFilters] = useState({
    StartDate: "",
    EndDate: "",
    status: [],
    severity: [],
    castype: [],
  });

  const [formikFields, setFormikFields] = useState({
    StartDate: "",
    EndDate: "",
    status: [],
    severity: [],
    castype: [],
  });

  function handlePageChange(pageNumber) {
    dispatch(
      handleTicketFilter({
        StartDate: formikFields.StartDate,
        EndDate: formikFields.EndDate,
        severity: [...formikFields.severity],
        status: [...formikFields.status],
        castype: [...formikFields.castype],
      })
    );
    dispatch(
      getSupportTickets({
        email: useralldata?.userinfo?.email,
        pageNumber,
        values: {
          StartDate: formikFields.StartDate,
          EndDate: formikFields.EndDate,
          severity: [...formikFields.severity],
          status: [...formikFields.status],
          castype: [...formikFields.castype],
        },
      })
    );
    setActivePage(pageNumber || { page: 1, pageSize: 10 });
  }

  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date().required("End date is required"),
    status: Yup.array().of(Yup.string()).required("Status is required"),
    severity: Yup.array().of(Yup.string()).required("Severity is required"),
    // Add validation for tiles if necessary
  });

  useEffect(() => {
    if (tickets.length <= 0) setLoader(false);
    else setLoader(true);
  }, [tickets.length <= 0]);

  return (
    <RequestWrapper className="dark:!bg-[#161616]">
      <FlexGrid fullWidth style={{ position: "relative" }}>
        <div className="header">
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem>
              <Link to="/">Support Center</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/">Create A Service Request</Link>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Link
                to="/request"
                style={{ color: "#000" }}
                className="dark:!text-[#fff]"
              >
                Service Requests{" "}
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>

          <h1>Service Requests</h1>
        </div>
        {/* </FlexGrid> */}

        {/* <FlexGrid fullWidth className="dark:!bg-[#161616]"> */}
        <div className="content  dark:!text-[#fff]">
          <Row className="subheading">
            <Column xs={10} sm={10} lg={8}>
              <p className="para dark:!text-[#fff]">
                Service Requests create a direct line to our team, ensuring
                prompt attention and assistance for your questions, issues, or
                requests.
              </p>
            </Column>
            <Column xs={10} lg={8} className="button_box">
              <CustomButton
                size={"lg"}
                fullWidth={width <= 1024}
                text="Create A Service Request"
                icon={Add}
                handleClick={() => {
                  Navigate("/TicketCreate");
                }}
                style={{ width: width > 768 && "331px" }}
              />
            </Column>
          </Row>

          {/*********** Service Request status  ***********/}
          <ServiceRequestStatus
            onStatusChange={(status) => {
              const newFilters = structuredClone(filters);

              if (status?.length) {
                newFilters.status = [{ id: status, text: status }];
              } else {
                newFilters.status = [];
              }
              setFilters(newFilters);

              dispatch(
                getSupportTickets({
                  email: useralldata?.userinfo?.email,
                  values: newFilters,
                  pageNumber: activePage.page ?? 1,
                })
              );
            }}
            filters={filters.status}
          />

          <div className="support-tab-content dark:!border-[#474747]">
            {(tickets.length > 0 && loading == "succeeded") ||
            (tickets.length == 0 && loading == "succeeded") ? (
              <>
                <DataTable
                  rows={tickets}
                  headers={Requesheader}
                  experimentalAutoAlign
                >
                  {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getTableProps,
                    onInputChange,
                  }) => (
                    <TableContainer>
                      <TableToolbar>
                        <TableToolbarContent style={{ height: "100%" }}>
                          <TableToolbarSearch
                            className="dark:!bg-[#262626]"
                            onChange={onInputChange}
                            placeholder="Search input text"
                            expanded
                          />

                          <div className="dark:!bg-[#161616] relative">
                            <div className="filter_mainbtn filter_container text-end flex justify-end">
                              {/* <FilterableMultiSelect
                            id="carbon-multiselect-example-3"
                            items={items}
                            key={filters.status
                              ?.map((status) => status.id)
                              .join("")}
                            placeholder="Status"
                            initialSelectedItems={filters.status}
                            itemToString={(item) => (item ? item.text : "")}
                            selectionFeedback="top-after-reopen"
                            onChange={({ selectedItems }) => {
                              const newFilters = {
                                ...filters,
                                status: selectedItems,
                              };
                              setFilters(newFilters);

                              dispatch(
                                getSupportTickets({
                                  email: useralldata?.userinfo?.email,
                                  values: newFilters,
                                })
                              );
                            }}
                            style={{
                              width: width > 768 && "168px",
                            }}
                          /> */}

                              <Button
                                style={{ width: "168px" }}
                                renderIcon={CaretDown}
                                onClick={() =>
                                  setFilterBtn((prevState) => !prevState)
                                }
                              >
                                Filter
                              </Button>
                            </div>

                            {filterBtn && (
                              <Formik
                                initialValues={filters}
                                // validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                  setFilters(values);
                                  dispatch(
                                    handleTicketFilter({
                                      StartDate: formikFields?.StartDate,
                                      EndDate: formikFields?.EndDate,
                                      severity: [...formikFields?.severity],
                                      status: [...formikFields?.status],
                                      castype: [...formikFields?.castype],
                                    })
                                  );
                                  dispatch(
                                    getSupportTickets({
                                      email: useralldata?.userinfo?.email,
                                      values: {
                                        ...formikFields,
                                        status: [...formikFields.status],
                                        severity: [...formikFields.severity],
                                        castype: [...formikFields.castype],
                                      },
                                      pageNumber: activePage.page ?? 1,
                                    })
                                  );

                                  setFilterBtn(false);
                                }}
                                enableReinitialize
                                onReset={() => {
                                  const values = {
                                    StartDate: "",
                                    EndDate: "",
                                    status: [],
                                    severity: [],
                                    castype: [],
                                  };

                                  setFilters(values);

                                  dispatch(
                                    handleTicketFilter({
                                      StartDate: "",
                                      EndDate: "",
                                      severity: [],
                                      status: [],
                                      castype: [],
                                    })
                                  );

                                  dispatch(
                                    getSupportTickets({
                                      email: useralldata?.userinfo?.email,
                                      values,
                                      pageNumber: activePage.page ?? 1,
                                    })
                                  );
                                }}
                              >
                                {({
                                  values,
                                  errors,
                                  touched,
                                  setFieldValue,
                                  isSubmitting,
                                  isValid,
                                  submitForm,
                                  resetForm,
                                }) => (
                                  <div className="filterDateform dark:!bg-[#161616]  bg-white border-[#e0e0e0] absolute top-full right-0">
                                    <div className="p-3 filterpanel dark:!bg-[#161616]">
                                      {/* Date Picker */}
                                      <DatePicker
                                        datePickerType="range"
                                        value={[
                                          TicketFilter?.StartDate,
                                          TicketFilter?.EndDate,
                                        ]}
                                        onChange={(event) => {
                                          // setFieldValue("StartDate", event[0]);
                                          // setFieldValue("EndDate", event[1]);
                                          dispatch(
                                            handleTicketFilter({
                                              StartDate: event[0],
                                              EndDate: event[1],
                                            })
                                          );
                                          setFormikFields((prevFilters) => ({
                                            ...prevFilters,
                                            StartDate: event[0],
                                            EndDate: event[1],
                                          }));
                                          setActivePage({
                                            page: 1,
                                            pageSize: 10,
                                          });
                                        }}
                                      >
                                        <DatePickerInput
                                          name="startDate"
                                          id="date-picker-input-id-start"
                                          placeholder="mm/dd/yyyy"
                                          labelText="Start date"
                                          size="md"
                                        />
                                        <DatePickerInput
                                          name="endDate"
                                          id="date-picker-input-id-finish"
                                          placeholder="mm/dd/yyyy"
                                          labelText="End date"
                                          size="md"
                                        />
                                      </DatePicker>

                                      {/* MultiSelect for Status */}
                                      <div style={{ margin: "5px 0" }}>
                                        <MultiSelect
                                          id="carbon-multiselect-example-3"
                                          titleText="Status"
                                          items={items}
                                          value={TicketFilter?.status}
                                          initialSelectedItems={
                                            TicketFilter?.status
                                          }
                                          itemToString={(item) =>
                                            item ? item.text : ""
                                          }
                                          selectionFeedback="top-after-reopen"
                                          onChange={(e) => {
                                            setFormikFields((prevFilters) => ({
                                              ...prevFilters,
                                              status: [...e.selectedItems],
                                            }));

                                            setActivePage({
                                              page: 1,
                                              pageSize: 10,
                                            });
                                          }}
                                        />
                                      </div>

                                      {/* MultiSelect for Severity */}
                                      <div style={{ margin: "5px 0" }}>
                                        <MultiSelect
                                          titleText="Severity"
                                          id="carbon-multiselect-example-2"
                                          items={Serivity}
                                          itemToString={(item) =>
                                            item ? item.text : ""
                                          }
                                          initialSelectedItems={
                                            TicketFilter?.severity
                                          }
                                          selectionFeedback="top-after-reopen"
                                          onChange={(e) => {
                                            setFormikFields((prevFilters) => ({
                                              ...prevFilters,
                                              severity: [...e.selectedItems],
                                            }));

                                            setActivePage({
                                              page: 1,
                                              pageSize: 10,
                                            });
                                          }}
                                        />
                                      </div>

                                      <div style={{ margin: "5px 0" }}>
                                        <MultiSelect
                                          titleText="Case Type"
                                          id="carbon-multiselect-example-4"
                                          items={caseType}
                                          itemToString={(item) =>
                                            item ? item.text : ""
                                          }
                                          initialSelectedItems={
                                            TicketFilter?.castype
                                          }
                                          selectionFeedback="top-after-reopen"
                                          onChange={(e) => {
                                            setFormikFields((prevFilters) => ({
                                              ...prevFilters,
                                              castype: [...e.selectedItems],
                                            }));

                                            setActivePage({
                                              page: 1,
                                              pageSize: 10,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="filterbtn_set ">
                                      <ButtonSet>
                                        <Button
                                          className="!bg-[#2570FE]"
                                          type="reset"
                                          style={{
                                            maxWidth: "156px",
                                          }}
                                          kind="secondary"
                                          onClick={() => {
                                            resetForm();
                                            setFilterBtn(false);
                                          }}
                                        >
                                          Reset
                                        </Button>
                                        <Button
                                          className="!bg-[#393939]"
                                          type="submit"
                                          onClick={submitForm}
                                          style={{
                                            maxWidth: "156px",
                                          }}
                                          kind="primary"
                                        >
                                          Apply
                                        </Button>
                                      </ButtonSet>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            )}
                          </div>
                        </TableToolbarContent>
                      </TableToolbar>
                      {tickets.length > 0 && loading == "succeeded" ? (
                        <Table
                          experimentalAutoAlign
                          {...getTableProps()}
                          isSortable
                        >
                          <TableHead>
                            <TableRow>
                              {headers.map((header) => (
                                <TableHeader
                                  className="dark:!text-white whitespace-nowrap "
                                  key={header.key}
                                  {...getHeaderProps({ header })}
                                >
                                  {header.header}
                                </TableHeader>
                              ))}
                              <TableHeader />
                            </TableRow>
                          </TableHead>
                          <TableBody experimentalAutoAlign>
                            {rows.map((row, row_inx) => (
                              <TableRow
                                className="table_row "
                                key={row.id}
                                onClick={() => {
                                  dispatch(handleActiveIndex(row_inx));
                                  navigate(`/ticketdetails/${row.id}`);
                                }}
                                // style={
                                //   row_inx == activeIndexOfTicket
                                //     ? getRowStyle()
                                //     : getInactiveRowStyle()
                                // }
                                {...getRowProps({ row })}
                              >
                                {row.cells.map((cell, inx) => {
                                  return (
                                    <React.Fragment key={cell.id}>
                                      <TableCell
                                        experimentalAutoAlign
                                        className={`!bg-[transparent] dark:!text-[white]  table_column ${
                                          inx === "statusColumnIndex"
                                            ? "status-cell"
                                            : ""
                                        }`}
                                      >
                                        {/* {cell.id.includes("created_at") &&
                                    tickets[row_inx]
                                      ?.created_at?
                                      :moment(
                                          tickets[row_inx]
                                            .created_at
                                        ).format("MM/DD/YY, h:mm A")} */}

                                        {cell.id.includes("status") &&
                                        tickets[row_inx]?.status ? (
                                          <span
                                            className="!bg-[#D0E2FF] dark:!bg-[#D0E2FF] !text-[#0043CE] px-3 py-1 "
                                            style={{ borderRadius: "1000px" }}
                                          >
                                            {/* // {getServiceRequestStatusText(
                                            //   tickets[row_inx].status
                                            // )} */}
                                            {cell.value}
                                          </span>
                                        ) : (
                                          cell.value
                                        )}
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                })}

                                {/* <TableCell className="cds--table-column-menu !bg-white">
                                <div
                                  style={{
                                    paddingTop: "1rem",
                                  }}
                                >
                                  <Link
                                    className="text-[#0F61FD]"
                                    to={`/ticketdetails/${row.id}`}
                                  >
                                    <Launch
                                      size={16}
                                      style={{
                                        color:
                                          context.globalTheme == "white"
                                            ? "#2570FE"
                                            : "#77A6FF",
                                      }}
                                    />
                                  </Link>
                                </div>
                              </TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <div className="no_data">
                          <div className="request_quote_div">
                            <RequestQuote size={68} />
                          </div>
                          <h6>No Requests</h6>
                          <span>
                            Create a new request to view the your <br /> status.
                          </span>
                        </div>
                      )}
                    </TableContainer>
                  )}
                </DataTable>
                <div className="dark:!text-white">
                  <Pagination
                    className="dark:!text-white"
                    backwardText="Previous page"
                    forwardText="Next page"
                    itemsPerPageText=""
                    page={activePage.page || 1}
                    pageSize={activePage.pageSize || 10}
                    pageNumberText="Page Number"
                    pageSizes={[10, 15]}
                    totalItems={count || 0}
                    size="md"
                    onChange={handlePageChange}
                  />
                </div>
              </>
            ) : loader ? (
              <DataTableSkeleton
                headers={Requesheader}
                aria-label="sample table"
              />
            ) : (
              <>
                <DataTable
                  rows={tickets}
                  headers={Requesheader}
                  experimentalAutoAlign
                >
                  {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getTableProps,
                    onInputChange,
                  }) => (
                    <TableContainer>
                      <TableToolbar>
                        <TableToolbarContent style={{ height: "100%" }}>
                          <TableToolbarSearch
                            className="dark:!bg-[#262626]"
                            onChange={onInputChange}
                            placeholder="Search input text"
                            expanded
                          />

                          <div className="dark:!bg-[#161616] relative">
                            <div className="filter_mainbtn filter_container text-end flex justify-end">
                              <Button
                                style={{
                                  backgroundColor:
                                    context.globalTheme == "white"
                                      ? "white"
                                      : "#262626",
                                  color:
                                    context.globalTheme == "white"
                                      ? "#161616"
                                      : "white",
                                  border:
                                    context.globalTheme == "white"
                                      ? "1px solid #e0e0e0"
                                      : "1px solid #494949",
                                  borderBottom: "1px solid #8d8d8d",
                                  width: "228px",
                                }}
                                renderIcon={ChevronDown}
                                onClick={() =>
                                  setFilterBtn((prevState) => !prevState)
                                }
                              >
                                Filter
                              </Button>
                            </div>

                            {filterBtn && (
                              <Formik
                                initialValues={filters}
                                // validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                  setFilters(values);
                                  dispatch(
                                    handleTicketFilter({
                                      StartDate: formikFields?.StartDate,
                                      EndDate: formikFields?.EndDate,
                                      severity: [...formikFields?.severity],
                                      status: [...formikFields?.status],
                                      castype: [...formikFields?.castype],
                                    })
                                  );
                                  dispatch(
                                    getSupportTickets({
                                      email: useralldata?.userinfo?.email,
                                      values: {
                                        ...formikFields,
                                        status: [...formikFields.status],
                                        severity: [...formikFields.severity],
                                        castype: [...formikFields.castype],
                                      },
                                      pageNumber: activePage.page ?? 1,
                                    })
                                  );

                                  setFilterBtn(false);
                                }}
                                enableReinitialize
                                onReset={() => {
                                  const values = {
                                    StartDate: "",
                                    EndDate: "",
                                    status: [],
                                    severity: [],
                                    castype: [],
                                  };

                                  setFilters(values);

                                  dispatch(
                                    handleTicketFilter({
                                      StartDate: "",
                                      EndDate: "",
                                      severity: [],
                                      status: [],
                                      castype: [],
                                    })
                                  );

                                  dispatch(
                                    getSupportTickets({
                                      email: useralldata?.userinfo?.email,
                                      values,
                                      pageNumber: activePage.page ?? 1,
                                    })
                                  );
                                }}
                              >
                                {({
                                  values,
                                  errors,
                                  touched,
                                  setFieldValue,
                                  isSubmitting,
                                  isValid,
                                  submitForm,
                                  resetForm,
                                }) => (
                                  <div className="filterDateform dark:!bg-[#161616]  bg-white border-[#e0e0e0] absolute top-full right-0">
                                    <div className="p-3 filterpanel dark:!bg-[#161616]">
                                      {/* Date Picker */}
                                      <DatePicker
                                        datePickerType="range"
                                        value={[
                                          TicketFilter?.StartDate,
                                          TicketFilter?.EndDate,
                                        ]}
                                        onChange={(event) => {
                                          // setFieldValue("StartDate", event[0]);
                                          // setFieldValue("EndDate", event[1]);
                                          dispatch(
                                            handleTicketFilter({
                                              StartDate: event[0],
                                              EndDate: event[1],
                                            })
                                          );
                                          setFormikFields((prevFilters) => ({
                                            ...prevFilters,
                                            StartDate: event[0],
                                            EndDate: event[1],
                                          }));
                                          setActivePage({
                                            page: 1,
                                            pageSize: 10,
                                          });
                                        }}
                                      >
                                        <DatePickerInput
                                          name="startDate"
                                          id="date-picker-input-id-start"
                                          placeholder="mm/dd/yyyy"
                                          labelText="Start date"
                                          size="md"
                                        />
                                        <DatePickerInput
                                          name="endDate"
                                          id="date-picker-input-id-finish"
                                          placeholder="mm/dd/yyyy"
                                          labelText="End date"
                                          size="md"
                                        />
                                      </DatePicker>

                                      {/* MultiSelect for Status */}
                                      <div style={{ margin: "5px 0" }}>
                                        <MultiSelect
                                          id="carbon-multiselect-example-3"
                                          titleText="Status"
                                          items={items}
                                          value={TicketFilter?.status}
                                          initialSelectedItems={
                                            TicketFilter?.status
                                          }
                                          itemToString={(item) =>
                                            item ? item.text : ""
                                          }
                                          selectionFeedback="top-after-reopen"
                                          onChange={(e) => {
                                            setFormikFields((prevFilters) => ({
                                              ...prevFilters,
                                              status: [...e.selectedItems],
                                            }));

                                            setActivePage({
                                              page: 1,
                                              pageSize: 10,
                                            });
                                          }}
                                        />
                                      </div>

                                      {/* MultiSelect for Severity */}
                                      <div style={{ margin: "5px 0" }}>
                                        <MultiSelect
                                          titleText="Severity"
                                          id="carbon-multiselect-example-2"
                                          items={Serivity}
                                          itemToString={(item) =>
                                            item ? item.text : ""
                                          }
                                          initialSelectedItems={
                                            TicketFilter?.severity
                                          }
                                          selectionFeedback="top-after-reopen"
                                          onChange={(e) => {
                                            setFormikFields((prevFilters) => ({
                                              ...prevFilters,
                                              severity: [...e.selectedItems],
                                            }));

                                            setActivePage({
                                              page: 1,
                                              pageSize: 10,
                                            });
                                          }}
                                        />
                                      </div>

                                      <div style={{ margin: "5px 0" }}>
                                        <MultiSelect
                                          titleText="Case Type"
                                          id="carbon-multiselect-example-4"
                                          items={caseType}
                                          itemToString={(item) =>
                                            item ? item.text : ""
                                          }
                                          initialSelectedItems={
                                            TicketFilter?.castype
                                          }
                                          selectionFeedback="top-after-reopen"
                                          onChange={(e) => {
                                            setFormikFields((prevFilters) => ({
                                              ...prevFilters,
                                              castype: [...e.selectedItems],
                                            }));

                                            setActivePage({
                                              page: 1,
                                              pageSize: 10,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="filterbtn_set ">
                                      <ButtonSet>
                                        <Button
                                          className="!bg-[#2570FE]"
                                          type="reset"
                                          style={{
                                            maxWidth: "156px",
                                          }}
                                          kind="secondary"
                                          onClick={() => {
                                            resetForm();
                                            setFilterBtn(false);
                                          }}
                                        >
                                          Reset
                                        </Button>
                                        <Button
                                          className="!bg-[#393939]"
                                          type="submit"
                                          onClick={submitForm}
                                          style={{
                                            maxWidth: "156px",
                                          }}
                                          kind="primary"
                                        >
                                          Apply
                                        </Button>
                                      </ButtonSet>
                                    </div>
                                  </div>
                                )}
                              </Formik>
                            )}
                          </div>
                        </TableToolbarContent>
                      </TableToolbar>
                      {tickets.length > 0 && loading == "succeeded" ? (
                        <Table
                          experimentalAutoAlign
                          {...getTableProps()}
                          isSortable
                        >
                          <TableHead>
                            <TableRow>
                              {headers.map((header) => (
                                <TableHeader
                                  className="dark:!text-white whitespace-nowrap "
                                  key={header.key}
                                  {...getHeaderProps({ header })}
                                >
                                  {header.header}
                                </TableHeader>
                              ))}
                              <TableHeader />
                            </TableRow>
                          </TableHead>
                          <TableBody experimentalAutoAlign>
                            {rows.map((row, row_inx) => (
                              <TableRow
                                className="table_row "
                                key={row.id}
                                onClick={() => {
                                  dispatch(handleActiveIndex(row_inx));
                                  navigate(`/ticketdetails/${row.id}`);
                                }}
                                {...getRowProps({ row })}
                              >
                                {row.cells.map((cell, inx) => {
                                  return (
                                    <React.Fragment key={cell.id}>
                                      <TableCell
                                        experimentalAutoAlign
                                        className={`!bg-[transparent] dark:!text-[white]  table_column ${
                                          inx === "statusColumnIndex"
                                            ? "status-cell"
                                            : ""
                                        }`}
                                      >
                                        <div className="no_data">
                                          <div className="request_quote_div">
                                            <RequestQuote size={68} />
                                          </div>
                                          <h6>No Requests</h6>
                                          <span>
                                            Create a new request to view the
                                            your <br /> status.
                                          </span>
                                        </div>
                                      </TableCell>
                                    </React.Fragment>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      ) : (
                        <div
                          className="no_data"
                          style={{
                            backgroundColor:
                              context.globalTheme == "white"
                                ? "white"
                                : "#262626",
                          }}
                        >
                          <div className="request_quote_div">
                            <RequestQuote size={68} />
                          </div>
                          <h6>No Requests</h6>
                          <span>
                            Create a new request to view the your <br /> status.
                          </span>
                        </div>
                      )}
                    </TableContainer>
                  )}
                </DataTable>
              </>
            )}
          </div>
        </div>
      </FlexGrid>
    </RequestWrapper>
  );
};

export default Request;
