import {
  ArrowLeft,
  ArrowRight,
  SoftwareResourceCluster,
} from "@carbon/icons-react";
import {
  Column,
  Row,
  PaginationNav,
  Loading,
  InlineLoading,
} from "@carbon/react";
import { Productdata, Servicedata } from "Component/Data/DataJson";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addMessage,
  getFaqCombineData,
  setSelectedService,
  setSidebarcontent,
} from "redux/slices/Chatbot/ChatbotSlice";
import useWindowSize from "hooks/useWindowSize";
import { useState, useEffect, useContext } from "react";
import { act } from "@testing-library/react";
import {
  getProductData,
  getServiceData,
  getTotalServiceAndProducts,
} from "redux/slices/TraningGuidens/TrainingGuidensSlice";
import { GlobalContext } from "ContextApi/GlobalContext";
import { setFullViewDispatcher } from "redux/slices/Chatbot/ChatbotSlice";

function ProductCardList() {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const context = useContext(GlobalContext);
  const [activeCardIndexForService, setActiveCardIndexForService] =
    useState(null);
  const [productPageNum, setProductPage] = useState(0);
  const [servicePageNum, setServicePage] = useState(0);
  const post_per_page = 9;
  const service_per_page = 9;

  const { productData, serviceData, TotalProductAndServiceData } = useSelector(
    (state) => state.Document
  );

  const activeRowStyles = {
    background: context?.globalTheme == "white" ? "#a8a8a8" : "#474747", // Light grey background for the active row
    border: "2px solid #0F62FE ",
  };

  const getStylesForService = (index) => {
    return index == activeCardIndexForService ? { ...activeRowStyles } : {};
  };

  const getStyles = (index) => {
    return index == activeCardIndex ? { ...activeRowStyles } : {};
  };

  useEffect(() => {
    dispatch(
      getTotalServiceAndProducts({
        page_num: productPageNum,
        post_per_page: post_per_page,
        service_per_page: service_per_page,
        service_page_num: servicePageNum,
      })
    );
  }, [productPageNum, servicePageNum]);

  function handleServiceClick(value) {
    dispatch(
      addMessage({
        text: `<p> Hello! I'm your virtual Help Center assistant. I'm here to help you find the answers and resources you need. How can I help <a href="#" data-service-name="${value.article_name}" > ${value.article_name} FAQ ? </a> </p>`,
        rawHTML: true,
        from: "bot",
      })
    );

    // dispatch(getServiceIssue({ text: value?.heading, ...value }));
    dispatch(setSelectedService(value));
  }

  return (
    <div className="aidata_faq">
      <div className="faq_aiheader">
        <div className="search_heading">
          <div className="faqicon dark:!bg-[#161616]">
            <SoftwareResourceCluster size={24} />
          </div>
          <div>
            <h6 className="dark:!text-white">AI Assistance </h6>
            <p className="dark:!text-white">
              Select your product or service, or engage in a conversation with
              your co-pilot for extra guidance.
            </p>
          </div>
        </div>

        <div className="faq_header_link">
          <Link
            to="/training/Training-For-admins"
            style={{ display: "flex", gap: "8px" }}
          >
            View Training For Admins <ArrowRight size={20} color="#2570Fe" />
          </Link>
        </div>
      </div>

      <div className="Product_section">
        <div style={{ marginBottom: "16px" }}>
          <h6 className="dark:!text-white">Latest Product FAQs</h6>
        </div>

        <div className="card-container">
          {width > 1025 &&
          TotalProductAndServiceData?.data?.data?.products?.length
            ? TotalProductAndServiceData?.data?.data?.products?.map(
                (value, index) => {
                  return (
                    <button
                      // style={getStyles(index)}
                      // style={index == activeCardIndex ? "active" : "single_produccolumn"}
                      className={
                        index == activeCardIndex
                          ? " active"
                          : "single_produccolumn"
                      }
                      role="button"
                      onClick={(e) => {
                        handleServiceClick(value);
                        setActiveCardIndex(index);
                      }}
                    >
                      <div className="single_producticon ">
                        <div className="single_icon">
                          <img
                            src={
                              value?.featured_image
                                ? value?.featured_image
                                : `${process.env.REACT_APP_IMAGE_URL}/Aws.png`
                            }
                            alt="google"
                          />
                        </div>
                        <Column
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            alignItems: "start",
                          }}
                        >
                          <h5 className="dark:!text-white !text-left">
                            {value?.article_name} FAQ
                          </h5>
                          <p className="dark:!text-[#525252]">
                            {value?.expert.split(" ").length > 15
                              ? value?.expert
                                  ?.split(" ")
                                  .slice(0, 13)
                                  .join(" ") + "..."
                              : value?.expert}
                          </p>
                        </Column>
                      </div>
                    </button>
                  );
                }
              )
            : width > 1025 &&
              // <InlineLoading
              //   small
              //   withOverlay
              //   loading={productData.status == "loading"}
              // />
              null}

          {width < 1025 &&
          TotalProductAndServiceData?.data?.data?.products?.length
            ? TotalProductAndServiceData?.data?.data?.products
                ?.slice(0, 3)
                .map((value, index) => {
                  return (
                    <button
                      // style={getStyles(index)}

                      // className="single_produccolumn"
                      className={
                        index == activeCardIndex
                          ? " active"
                          : "single_produccolumn"
                      }
                      role="button"
                      onClick={(e) => {
                        handleServiceClick(value);
                        setActiveCardIndex(index);
                        dispatch(setFullViewDispatcher(true));
                      }}
                    >
                      <div className="single_producticon ">
                        <div className="single_icon">
                          <img
                            src={
                              value?.featured_image
                                ? value?.featured_image
                                : `${process.env.REACT_APP_IMAGE_URL}/Aws.png`
                            }
                            alt="google"
                          />
                        </div>
                        <Column
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            alignItems: "start",
                          }}
                        >
                          <h5 className="dark:!text-white !text-left">
                            {value?.article_name} FAQ
                          </h5>
                          <p className="dark:!text-[#525252]">
                            {value?.expert.split(" ").length > 15
                              ? value?.expert
                                  ?.split(" ")
                                  .slice(0, 13)
                                  .join(" ") + "..."
                              : value?.expert}
                          </p>
                        </Column>
                      </div>
                    </button>
                  );
                })
            : width < 1025 &&
              // <InlineLoading
              //   small
              //   withOverlay
              //   loading={productData.status == "loading"}
              // />
              null}
        </div>

        {TotalProductAndServiceData?.data?.data?.total_products_page > 1 && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
            }}
          >
            <PaginationNav
              page={productPageNum}
              itemsShown={width > 768 ? 6 : 5}
              totalItems={
                TotalProductAndServiceData?.data?.data?.total_products_page || 0
              }
              onChange={(e) => {
                setProductPage(e);
              }}
            />
          </div>
        )}
      </div>

      <div className="Product_section">
        <div style={{ marginBottom: "16px" }}>
          <h6 className="dark:!text-white"> Latest Service FAQs</h6>
        </div>

        <div className="card-container">
          {
            width > 1025 &&
            TotalProductAndServiceData?.data?.data?.services?.length
              ? TotalProductAndServiceData?.data?.data?.services?.map(
                  (value, index) => {
                    return (
                      <button
                        // style={getStylesForService(index)}
                        className={
                          index == activeCardIndexForService
                            ? " active"
                            : "single_produccolumn"
                        }
                        // className="single_produccolumn"
                        role="button"
                        onClick={(e) => {
                          handleServiceClick(value);
                          setActiveCardIndexForService(index);
                        }}
                      >
                        <div className="single_producticon dark:!border-[#474747]">
                          <div className="single_icon">
                            <img
                              src={
                                value?.featured_image
                                  ? value?.featured_image
                                  : `${process.env.REACT_APP_IMAGE_URL}/Aws.png`
                              }
                              alt="google"
                            />
                          </div>
                          <Column
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              alignItems: "start",
                            }}
                          >
                            <h5 className="dark:!text-white !text-left">
                              {value?.article_name} FAQ
                            </h5>
                            <p className="dark:!text-[#525252]">
                              {value?.expert.split(" ").length > 15
                                ? value?.expert
                                    ?.split(" ")
                                    .slice(0, 13)
                                    .join(" ") + "..."
                                : value?.expert}
                            </p>
                          </Column>
                        </div>
                      </button>
                    );
                  }
                )
              : width > 1025 && null
            // <InlineLoading
            //   small
            //   withOverlay
            //   loading={productData.status == "loading"}
            // />
          }

          {width < 1025 &&
          TotalProductAndServiceData?.data?.data?.services?.length
            ? TotalProductAndServiceData?.data?.data?.services
                ?.slice(0, 3)
                .map((value, index) => {
                  return (
                    <button
                      // style={getStylesForService(index)}
                      // className="single_produccolumn"
                      className={
                        index == activeCardIndexForService
                          ? " active"
                          : "single_produccolumn"
                      }
                      role="button"
                      onClick={(e) => {
                        handleServiceClick(value);
                        setActiveCardIndexForService(index);
                        dispatch(setFullViewDispatcher(true));
                      }}
                    >
                      <div className="single_producticon dark:!border-[#474747]">
                        <div className="single_icon">
                          <img
                            src={
                              value?.featured_image
                                ? value?.featured_image
                                : `${process.env.REACT_APP_IMAGE_URL}/Aws.png`
                            }
                            alt="google"
                          />
                        </div>
                        <Column
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            alignItems: "start",
                          }}
                        >
                          <h5 className="dark:!text-white !text-left">
                            {value?.article_name} FAQ
                          </h5>
                          <p className="dark:!text-[#525252]">
                            {value?.expert.split(" ").length > 15
                              ? value?.expert
                                  ?.split(" ")
                                  .slice(0, 13)
                                  .join(" ") + "..."
                              : value?.expert}
                          </p>
                        </Column>
                      </div>
                    </button>
                  );
                })
            : width < 1025 &&
              // <InlineLoading
              //   small
              //   withOverlay
              //   loading={serviceData.status == "loading"}
              // />
              null}
        </div>
        {TotalProductAndServiceData?.data?.data?.total_service_page > 1 && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <PaginationNav
              page={servicePageNum}
              itemsShown={
                width > 768
                  ? Math.ceil(
                      (
                        TotalProductAndServiceData?.data?.data?.total_services /
                        5
                      ).toFixed(1)
                    )
                  : 5
              }
              totalItems={
                TotalProductAndServiceData?.data?.data?.total_service_page || 0
              }
              onChange={(e) => setServicePage(e)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductCardList;
