import styled from "styled-components";

export const Notificationwrapper = styled.section`
  background: #f4f4f4
    url(${process.env.REACT_IMAGE_PATH}/notificationbackground.png);

  margin-top: 3rem;
  position: relative;
  height: 95vh;
  background-position: top right 0;
  background-size: contain;
  background-repeat: no-repeat;

  .cds--toolbar-content .cds--search .cds--search-input {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#fff" : "#262626"} !important;
    border-block-end: none;
  }

  .cds--pagination {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#fff" : "#262626"} !important;
  }

  .notification_table {
    z-index: 100;
    .flatpickr-calendar.open {
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"} !important;
    }

    td {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#393939" : "white"};
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.014px;
      &:first-child {
        min-width: 700px;
        @media screen and (max-width: 768px) {
          min-width: 200px;
          max-width: 200px;
        }
      }

      &:nth-child(2) {
        @media screen and (max-width: 768px) {
          /* padding-inline-end: 0 !important; */
          min-width: 104px;
          max-width: 104pxpx;
        }
      }

      &:nth-child(3) {
        /* padding-inline-start: 1rem !important; */
      }
    }
  }

  .gridcolor {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"} !important;

    .notificationdata_table {
      margin: 2rem 0px;
      .cds--data-table-container {
        border: none;
      }

      .no_data {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 87px;
        padding-bottom: 106px;
        padding-left: 120px;
        gap: 12px;
        @media screen and (max-width: 768px) {
          padding-top: 102px;
        }

        .request_quote_div {
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 4px;
          svg {
            color: #a8a8a8;
          }
        }
        h6 {
          color: var(--Text-text-placeholder, #a8a8a8);
          font-family: "IBM Plex Sans";
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 35px; /* 159.091% */
        }

        span {
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#d1d1d1" : "white"};

          /* Fixed heading styles/heading-reg-07 */
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.16px;
        }
      }

      .cds--toolbar-content {
        @media screen and (max-width: 768px) {
          display: block;
        }
      }
      .cds--data-table-content {
        @media screen and (max-width: 768px) {
          margin-top: 48px;
        }
      }
      .cds--data-table-container {
        border: 1px solid var(--border-subtle, #e0e0e0);
        padding: unset !important;
      }

      .cds--table-sort__flex {
        @media screen and (max-width: 768px) {
          padding-right: 1.5rem;
        }
      }
      .call_system {
        display: flex;
        background-color: var(--tag-blue-tag-background-blue, #d0e2ff);
        width: 70px;
        border-radius: 1000px;
        padding: 5px;
        color: var(--tag-blue-tag-color-blue, #0043ce);
        font-family: IBM Plex Sans;
        font-size: 12px;
        font-style: normal;
        justify-content: center;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.32px;
      }
    }
  }
  .headingdiv {
    padding: 32px 0px 21px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: 768px) {
      padding: 33px 0 33px 0;
    }

    h1 {
      color: var(--accessibility-black, #000);
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
    }
    p {
      color: #161616;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.014px;
    }
  }
  .bgimage {
    position: absolute;
    right: 12.5%;
    margin-top: 8px;
    top: 0;
  }
  .filter_mainbtn {
    color: #fff;
    position: relative;
    min-width: 217px;
    .cds--btn--primary {
      width: 100%;
    }
    .filterDateform {
      position: absolute;
      right: 0;
      padding: 10px;
      border: 1px solid var(--border-border-subtle-00, #e0e0e0);
      background: var(--background-or-layer, #fff);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      gap: 20px;
      .filterbtn_set {
        width: 100%;
        .cds--btn--primary {
          width: 50%;
        }
        .cds--btn--secondary {
          width: 50%;
        }
      }
    }
  }

  .cds--skeleton {
    overflow: auto;

    .cds--table-header-label {
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#161616" : "white"} !important;
    }
  }
`;

export const CustomWrapperForNotification = styled.section`
  .cds--header-panel {
    .cds--header-panel--expanded {
      width: 300px;
      background-color: "white";
    }
  }
`;
