import styled from "styled-components";

const HeaderWrapper = styled.section`
  .cds--header__action--active {
    background-color: #000;
  }
  .cds--header__menu-bar {
    display: flex;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  svg {
    fill: white !important;
  }

  .mani_div {
    width: 100%;

    @media screen and (max-width: 1920px) {
      .main_grid {
        padding-left: 16px;
      }
    }
  }
  header {
    @media screen and (max-width: 767px) {
      padding: 0;
      .logo {
        margin-left: 1rem;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      gap: 20px;
      a {
        display: flex;
        align-items: center;
        color: unset;
        gap: 8px;
      }
      .cds--header__menu-bar {
        gap: 2rem;
        @media screen and (max-width: 1699px) {
          gap: 0;
        }
        a {
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.1599999964237213px;
          text-align: left;
        }
      }
    }
    .user {
      width: 100%;
      gap: 8px;
      padding: 0 1rem;
      span {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.1599999964237213px;
        text-align: left;
      }
    }
  }
  span.notification_count {
    background: #e5141e;
    border-radius: 100%;
    height: 19px;
    width: 19px;
    padding: 2px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    margin-bottom: 1rem;
  }

  .navbaridentity {
    display: block;
    height: 3rem;

    .cds--header__menu-item {
      border: none !important;
    }

    .cds--header__menu-item:focus,
    .cds--header__menu-item:focus-visible {
      border-color: none !important;
    }

    a {
      color: #fff;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .serach_list {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        border: 1px solid #ddd;
        margin-top: -1px; /* Prevent double borders */
        background-color: #fff;
        padding: 12px;
        text-decoration: none;
        font-size: 18px;
        color: black;
        display: block;

        &:hover {
          background-color: #e4e4e4;
        }
        .product-detail-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .header_button {
    text-decoration: none !important;
    color: unset;
  }
`;

export default HeaderWrapper;
