import { PublicClientApplication } from "@azure/msal-browser";
import { io } from "socket.io-client";
import { msalConfig } from "./authConfig";
const msalInstance = new PublicClientApplication(msalConfig);
let azureId = msalInstance.getAllAccounts()[0]?.idTokenClaims?.sub;

var socket = io.connect(`${process.env.REACT_APP_SOCKETURL}`, {
  path: `${process.env.REACT_APP_SOCKET_PATH}`,
});
socket.on("connect", (i) => {
  let joinInterval = setInterval(() => {
    if (
      azureId &&
      azureId != undefined &&
      azureId != "Undefined" &&
      azureId != "undefined"
    ) {
      socket.emit("join", { userid: azureId });
      clearInterval(joinInterval);
    }
  }, 1000);
});

export default socket;
