import { useMsal } from "@azure/msal-react";
import { Button, CopyButton, HeaderPanel } from "@carbon/react";
import { useDispatch, useSelector } from "react-redux";
import { handleThemeModal } from "redux/slices/headerSlice";
import AccountWrapper from "./styles";
import { useContext } from "react";
import { Contextprovider } from "App";
import { GlobalContext } from "ContextApi/GlobalContext";
import { msalConfig } from "authConfig";
import socket from "socket";
import { User } from "@carbon/icons-react";

const Account = ({ open }) => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const user = useSelector((state) => state.salesForce.userInfoData.data);
  const { useralldata } = useContext(Contextprovider);
  const context = useContext(GlobalContext);
  const handleCopy = () => {
    navigator.clipboard.writeText(user.contactInfo.clientId);
  };

  if (!open) return null;

  return (
    <AccountWrapper>
      <HeaderPanel
        aria-label="Account Panel"
        className="account_panel "
        addFocusListeners={false}
        expanded={open}
      >
        <div className="top">
          <div>
            <h6>Account ID</h6>

            <div className="left  ">
              <span>{useralldata?.userinfo?.AccountId}</span>
              <CopyButton
                className="bg-[#393939] "
                iconDescription="Copy"
                align="bottom"
                onClick={handleCopy}
                feedback="Copied"
              />
            </div>
          </div>
          <div className="right ">
            {useralldata?.userinfo?.ProfileImage ? (
              <img
                src={useralldata?.userinfo?.ProfileImage}
                alt="Profileimage"
              />
            ) : (
              <User size={32} />
            )}
          </div>
        </div>
        <ul className="">
          <li>
            <button
              className=""
              onClick={() => {
                window.open("https://identity.uvation.com/account", "_blank");
              }}
            >
              Profile
            </button>
          </li>
        </ul>
        <ul>
          <li>
            <button onClick={() => dispatch(handleThemeModal())}>
              Change Theme
            </button>
          </li>
        </ul>
        <Button
          className="logout_button"
          size="lg"
          onClick={() => {
            socket?.emit("logout", {
              userid: useralldata?.userInfo?.userid,
              time: new Date(),
            });
            sessionStorage.clear();
            instance.logoutRedirect(msalConfig.auth.postLogoutRedirectUri);
          }}
        >
          Log Out
        </Button>
      </HeaderPanel>
    </AccountWrapper>
  );
};

export default Account;
