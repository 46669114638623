import {
  CheckmarkOutline,
  MisuseOutline,
  Settings,
  WarningAlt,
  WarningHex,
  WatsonHealthStatusAcknowledge,
} from "@carbon/icons-react";
import axios from "axios";
import ServiceRequestWrapper from "components/ServiceRequestStatus/style";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import StatusWrapper from "../style";
import useWindowSize from "hooks/useWindowSize";
import axiosInstance from "axiosintance";

const Serviceindex = () => {
  const [counts, setCounts] = useState({
    degradedPerformanceCount: 0,
    operationalCount: 0,
    majorOutageCount: 0,
    underMaintenance: 0,
    partialOutage: 0,
  });
  const [searchParams] = useSearchParams();
  const activeFilter = searchParams.get("status");

  useEffect(() => {
    axiosInstance.get(process.env.REACT_APP_COMPONENTS_URL).then((res) => {
      let degradedPerformanceCount = 0;
      let operationalCount = 0;
      let majorOutageCount = 0;
      let underMaintenance = 0;
      let partialOutage = 0;

      res?.data?.length &&
        res?.data?.forEach((item) => {
          switch (item.status) {
            case "degraded_performance":
              degradedPerformanceCount++;
              break;
            case "operational":
              operationalCount++;
              break;
            case "major_outage":
              majorOutageCount++;
              break;
            case "maintenance": // Assuming the correct status string is 'maintenance'
              underMaintenance++;
              break;
            case "partial_outage":
              partialOutage++;
              break;
            default:
            // Handle other statuses if necessary
          }
        });

      setCounts({
        degradedPerformanceCount,
        operationalCount,
        majorOutageCount,
        underMaintenance,
        partialOutage,
      });
    });
  }, []);

  function getActiveClass(status) {
    if (window.location.pathname === "/") return "";

    if (status === "all" && !activeFilter) {
      return "active";
    }

    if (status === activeFilter) {
      return "active";
    }
  }
  const { width } = useWindowSize();

  return (
    <StatusWrapper>
      <ServiceRequestWrapper className="dark:!bg-[#262626] !bg-[#fff]">
        <div className="heading">
          <div className="icon_bo">
            <WatsonHealthStatusAcknowledge size={24} />
          </div>
          <h5 className="dark:!text-[#fff]">Service Statuses</h5>
        </div>

        <Link
          to={"/status"}
          className={`status_card_data  dark:!bg-[#161616] ${getActiveClass(
            "all"
          )}`}
        >
          <div
            style={{ backgroundColor: "#0F62FE" }}
            className="icon_maintence"
          >
            <Settings size={16} />
          </div>
          <div className="status_card_Page dark:!bg-[#161616]">
            <h6 className="status_card_heading  dark:text-[#fff]">
              Under Maintenance
            </h6>
            <h6>{counts?.underMaintenance}</h6>
          </div>
        </Link>

        <Link
          to={"/status?status=operational"}
          className={`status_card_data dark:!bg-[#161616] ${getActiveClass(
            "operational"
          )}`}
        >
          <div
            className="icon_maintence"
            style={{ backgroundColor: "#198038" }}
          >
            <CheckmarkOutline size={16} />
          </div>
          <div className="status_card_Page dark:!bg-[#161616]">
            <h6>Operational</h6>
            <h6>{counts?.operationalCount}</h6>
          </div>
        </Link>

        <Link
          to={"/status?status=partial_outage"}
          className={`status_card_data dark:!bg-[#161616] ${getActiveClass(
            "partial_outage"
          )}`}
        >
          <div
            className="icon_maintence"
            style={{ backgroundColor: "#F1C21B" }}
          >
            <WarningAlt size={16} />
          </div>
          <div className="status_card_Page dark:!bg-[#161616]">
            <h6>Partial Outage</h6>
            <h6>{counts?.partialOutage}</h6>
          </div>
        </Link>

        <Link
          to={"/status?status=major_outage"}
          className={`status_card_data dark:!bg-[#161616] ${getActiveClass(
            "major_outage"
          )}`}
        >
          <div
            className="icon_maintence"
            style={{ backgroundColor: "#B81922" }}
          >
            <MisuseOutline size={16} />
          </div>
          <div className="status_card_Page dark:!bg-[#161616]">
            <h6>Major Outage</h6>
            <h6>{counts?.majorOutageCount}</h6>
          </div>
        </Link>

        <Link
          to={"/status?status=degraded_performance"}
          className={`status_card_data dark:!bg-[#161616] ${getActiveClass(
            "degraded_performance"
          )}`}
        >
          <div
            className="icon_maintence"
            style={{ backgroundColor: "#FF832B" }}
          >
            <WarningHex size={16} />
          </div>
          <div className="status_card_Page dark:!bg-[#161616]">
            <h6>Degraded Performance</h6>
            <h6>{counts?.degradedPerformanceCount}</h6>
          </div>
        </Link>
      </ServiceRequestWrapper>
    </StatusWrapper>
  );
};

export default Serviceindex;
