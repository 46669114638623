import styled from "styled-components";
const PlanWrapper = styled.main`
  position: relative;

  /* background-color: #f4f4f4; */
  background: url(${process.env.REACT_APP_IMAGE_URL}/requestbackgound.png),
    #f4f4f4;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 768px) {
    background: url(${process.env
        .REACT_APP_IMAGE_URL}/mobilebackgroundgraphic.png),
      #f4f4f4;
    background-position: top right 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .header {
    padding: 32px 0;

    @media screen and (max-width: 768px) {
      padding: 32px 0 25px 0;
    }

    h4 {
      font-family: IBM Plex Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.32px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
      z-index: 1;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#525252" : "#7E7E7E"};
    }
    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      font-style: normal;
      letter-spacing: 0px;
      z-index: 1;
      text-align: left;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#000" : "#fff"};
      @media screen and (max-width: 768px) {
        line-height: 44px;
      }
    }
  }
  .plans-content {
    padding-top: 2rem;
    p {
      color: var(--Text-text-primary, #161616);

      /* Body styles/body-01 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.16px;
    }
  }
  .plans-terms-and-conditions {
    padding: 56px 0;

    .terms-and-condition-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      h5 {
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.014px;
        margin-bottom: 0.5rem;
        font-family: "IBM Plex Sans";
      }
      p {
        a {
          color: var(--link-link-primary, #0f62fe);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.16px;
        }
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.16px;
      }
    }
  }
  .plans-description-table-wrapper {
    padding-top: 2rem;
    overflow: auto;
    z-index: 99999;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

    .launch_icon_div {
      color: var(--Accessibility-Black, #000);
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.16px;
      word-break: break-word;

      h5 {
        color: var(--link-link-primary, var(--Default-Blue-60, #2570fe));
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
    }

    table {
      width: 100%;
      tr {
        height: 112px;

        .table-first-header {
          /* background: #f4f4f4; */
        }
        .table-heading {
          color: #000;
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
          min-width: 91px;

          .container-with-icon {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            align-items: center;
          }
        }
        td {
          /* border: ${(props) =>
            props.theme.colorScheme == "white"
              ? "1px solid #e0e0e0"
              : "1px solid  #474747"}; */
          padding: 1rem;
          /* width: calc(100% / 4); */
          max-width: 396px;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          font-family: Arial;
          letter-spacing: 0.16px;
          color: #000;

          @media screen and (max-width: 768px) {
            font-size: 14px;
            max-width: 129px;
          }

          &:first-child {
            /* background: var(--field-hover, #e8e8e8); */
          }
          &:nth-child(3) {
            min-width: 109px;
          }
        }
        th {
          padding: 1rem;
          width: calc(100% / 4);
          vertical-align: middle;
          &:first-of-type {
            border: 0;
          }
          &:nth-child(2) {
            /* background: var(--field, #f4f4f4); */
          }
        }
      }
    }
    .master_div {
      display: flex;
      align-items: space-between;
      flex-direction: column;
      gap: 16px;
      max-height: 225px;
      overflow: auto;
      .launch_icon_div {
        color: var(--Accessibility-Black, #000);
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 142.857% */
        letter-spacing: 0.16px;
        display: flex;
        /* align-items: space-between; */
        gap: 8px;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          gap: 0px;
          width: 98px;
        }

        .img_div {
          width: 24px;
          height: 24px;
          background: ${(props) =>
            props.theme.colorScheme == "white" ? "#e8e8e8" : "#262626"};
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .case-response-time-table {
    overflow: auto;
    z-index: 999;
    padding-top: 48px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    .table {
      width: 100%;
      /* border: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid var(--border-subtle, #e0e0e0) "
          : "1px solid var(--border-subtle, #474747)"}; */
      tr {
        /* border: ${(props) =>
          props.theme.colorScheme == "white"
            ? "1px solid var(--border-subtle, #e0e0e0) "
            : "1px solid var(--border-subtle, #474747)"}; */
        &:first-of-type {
          background: var(--layer-accent-layer-accent-01, #e0e0e0);
        }
        td {
          width: 13%;
          padding: 1rem 0 32px 1rem;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(
            --text-text-secondary,
            var(--Text-text-secondary, #525252)
          );

          @media screen and (max-width: 768px) {
            max-width: 120px;
            width: 120px;
          }

          &:nth-of-type(3) {
            width: 35%;
            @media screen and (max-width: 768px) {
              max-width: 120px;
            }
          }
        }
        th {
          padding: 1rem;
          width: 13%;
          text-align: start;
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.16px;
          overflow-wrap: unset;
          @media screen and (max-width: 768px) {
            max-width: 120px;
            width: 120px;
          }
          &:nth-of-type(2) {
            @media screen and (max-width: 768px) {
              min-width: 150px;
            }
          }
          &:nth-of-type(3) {
            width: 35%;
            @media screen and (max-width: 768px) {
              max-width: 120px;
              width: 120px;
            }
          }
        }
      }
    }
  }
  .critical_mainheading {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 10px;
    padding: 1rem 0;
    position: sticky;

    border-bottom: 1px solid var(--border-strong, #8d8d8d);
    background: var(--field, #f4f4f4);

    h6 {
      font-family: IBM Plex Sans;
      font-size: 20px;
      font-style: normal;
      line-height: normal;
    }
    p {
      /* Body styles/body-01 */
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.16px;
      max-width: 779px;
      min-width: 779px;
      padding-bottom: 16px;
    }
  }
`;
export default PlanWrapper;
