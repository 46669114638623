import React, { useContext, useMemo, useState } from "react";
import ServiceRequestWrapper from "./style";
import { Document, UserData } from "@carbon/icons-react";
import { Contextprovider } from "App";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { handleActiveStatusOfServices } from "../../redux/slices/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { headerState } from "redux/slices/headerSlice";
/**
 *
 * @param {React.PropsWithChildren<{onStatusChange?: (payload: string) => void, filters?: any[]}>} param0
 * @returns
 */
const ServiceRequestStatus = ({ onStatusChange, filters = [] }) => {
  const { useralldata } = useContext(Contextprovider);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = useMemo(
    () => Object.fromEntries(filters?.map((filter) => [filter.id, true])),
    [filters]
  );

  const isAllFilterEnabled = useMemo(() => filters.length === 0, [filters]);
  const { width } = useWindowSize();
  const { activeStatusOfServiceStatus } = useSelector(headerState);

  return (
    <ServiceRequestWrapper className="dark:!bg-[#262626]">
      <div className="heading">
        <div className="icon_box ">
          <Document size={24} />
        </div>
        <h5 className="service_request_text text-themeBlack dark:!text-[#fff]">
          Service Request Statuses
        </h5>
      </div>

      <div
        onClick={() => {
          if (onStatusChange) return onStatusChange("");
          navigate("/request");
        }}
        className={`status_card dark:!bg-[#161616] dark:!text-[#fff] ${
          isAllFilterEnabled ? "active" : ""
        } `}
      >
        <h5 className="dark:!text-[#fff]">All Requests </h5>
        <h5 className="dark:!text-[#fff]">
          {Number(useralldata.GlobalState?.newCount) +
            Number(useralldata.GlobalState?.openCount) +
            Number(useralldata.GlobalState?.pendingCount) +
            Number(useralldata.GlobalState?.closedCount) || 0}
        </h5>
      </div>

      <div
        onClick={() => {
          if (onStatusChange) return onStatusChange("Open");
          navigate(`/request?status=Open`);
        }}
        className={`status_card dark:!bg-[#161616] ${
          status["Open"] ? "active" : ""
        }`}
      >
        <h5 className="dark:!text-[#fff]">Open</h5>
        <h5 className="dark:!text-[#fff]">
          {useralldata.GlobalState?.newCount ?? 0}
        </h5>
      </div>

      <div
        onClick={() => {
          if (onStatusChange) return onStatusChange("Assigned");
          navigate("/request?status=Assigned");
        }}
        className={`status_card dark:!bg-[#161616] ${
          status["Assigned"] ? "active" : ""
        }`}
      >
        <h5 className="dark:!text-[#fff]">Assigned</h5>
        <h5 className="dark:!text-[#fff]">
          {useralldata.GlobalState?.openCount ?? 0}
        </h5>
      </div>

      <div
        onClick={() => {
          if (onStatusChange) return onStatusChange("Pending");
          navigate("/request?status=Pending");
        }}
        className={`status_card dark:!bg-[#161616] ${
          status["Pending"] ? "active" : ""
        }`}
      >
        <h5 className="dark:!text-[#fff]">In Progress</h5>
        <h5 className="dark:!text-[#fff]">
          {useralldata.GlobalState?.pendingCount ?? 0}
        </h5>
      </div>

      <div
        onClick={() => {
          if (onStatusChange) return onStatusChange("Closed");
          navigate("/request?status=Closed");
        }}
        className={`status_card dark:!bg-[#161616] ${
          status["Closed"] ? "active" : ""
        }`}
      >
        <h5 className="dark:!text-[#fff]">Closed</h5>
        <h5 className="dark:!text-[#fff]">
          {useralldata.GlobalState?.closedCount ?? 0}
        </h5>
      </div>
    </ServiceRequestWrapper>
  );
};

export default ServiceRequestStatus;
