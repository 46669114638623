const { default: styled } = require("styled-components");

export const TraningAdminswrapper = styled.section`
  background-image: url(${process.env
    .REACT_APP_IMAGE_URL}/headerbackground.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-color: ${(props) =>
    props.theme.colorScheme == "white" ? "#f4f4f4" : "#161616"};

  @media screen and (max-width: 768px) {
    background-image: url(${process.env
      .REACT_APP_IMAGE_URL}/faqheadermobileimage.png);
  }

  .side_tab {
    /* position: fixed;
    top: 90px;*/
    /* height: calc(100% - 90px); */
    /* width: 20rem; */
    overflow-y: auto;

    .cds--side-nav__items {
      padding: 24px 0 16px 0;
    }
    a.cds--side-nav__link {
      padding: 0;
    }

    .accordion {
      padding: 16px 16px 0 16px;
      .cds--accordion__item {
        border-block-start: none;
        border-block-end: 1px solid var(--cds-border-subtle);
      }

      .cds--checkbox-label-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #525252;

        /* Fixed heading styles/heading-reg-07 */
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
      .cds--accordion__title {
        /* Fixed heading styles/heading-semi-06 */
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
      .cds--checkbox-label::before {
        border: 1px solid #525252;
      }

      @media screen and (max-width: 768px) {
        .cds--accordion__content {
          padding-inline: 0.7rem !important;
        }
      }
    }

    .header {
      display: flex;
      padding: 0px 16px;

      .cds--header__action {
        margin-top: -16px;
        padding-left: 24px;
      }

      h2 {
        color: var(--Text-text-primary, #474242);
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
        width: calc(100% - var(--cds-spacing-09, 3rem));
      }
    }

    hr {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#e0e0e0" : "#474747"};
    }
  }

  .close_btn {
    .cds--btn {
      padding-inline-end: 0 !important;
    }
  }

  .cds--modal.is-visible {
    overflow-y: scroll;
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }

  .cds--modal-container {
    margin-top: 90px;
    width: 256px;
  }

  .cds--modal {
    display: block;
  }

  .cds--modal-content {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#f4f4f4" : "#262626"};
  }

  .cds--modal-header {
    margin-block-end: 0rem;
    padding-block-start: 0rem;
    padding-inline-end: 0rem;
    padding-inline-start: 0rem;
  }

  .training_adminsmainheading {
    padding: 32px 0 24px 0;

    .cds--search-input {
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "white" : "#393939"};
    }

    h6 {
      color: var(--Text-text-secondary, #525252);

      font-family: "IBM Plex Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px; /* 141.667% */
      letter-spacing: 0.32px;
    }
    h4 {
      color: var(--Accessibility-Black, #000);
      font-family: "IBM Plex Sans";
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 157.143% */
    }
  }
  .card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    @media screen and (max-width: 66rem) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 53rem) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .View_moredata {
    flex-direction: column;
    display: flex;
    min-height: 100%;
    padding: 16px;
    justify-content: space-between;
    border: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid  #e0e0e0"
        : "1px solid  #474747"};
    .View_moredata_cards {
      .cards-body {
        display: flex;
        row-gap: 1rem;
        flex-direction: column;
      }
      display: flex;
      flex-direction: column;
      gap: 16px;
      .medilling_Icon {
        display: flex;
        align-items: center;
        gap: 10px;
        .Icon1 {
          background: linear-gradient(135deg, #f4f4f4 50%, #e0e0e0 50%);
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
        }
        .Icon2 {
          background-color: #f4f4f4;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
        }
      }
      ul {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(
          --layer-layer-selected-disabled,
          var(--Layer-layer-selected-disabled, #8d8d8d)
        );

        font-family: "IBM Plex Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 141.667% */
        letter-spacing: 0.32px;

        li {
          list-style: disc;
          list-style-position: inside;
        }
      }
      h4 {
        font-family: "IBM Plex Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* height: 84px; */
      }
      h5 {
        font-family: "IBM Plex Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
      p {
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
      }
      .time {
        color: var(
          --layer-layer-selected-disabled,
          var(--Layer-layer-selected-disabled, #8d8d8d)
        );

        /* Subhead lg - IBM Plex Sans */
        font-family: "IBM Plex Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px; /* 141.667% */
        letter-spacing: 0.32px;
      }
    }
    .View_moredata_cards_fix {
      height: 100%;
      justify-content: space-between;
    }
  }
  .mainfilter_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    h1 {
      color: var(--Accessibility-Black, #000);
      font-family: "IBM Plex Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }
    h6 {
      color: var(--Default-Gray-50, #8d8d8d);
      /* Fixed heading styles/heading-light-06 */
      font-family: "IBM Plex Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
    }
  }
  .filter_data {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .cds--checkbox-label-text {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#525252" : "#8d8d8d"};
    }

    ul {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 353px;
      min-height: 353px;
      border-top: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid  #e0e0e0"
          : "1px solid  #474747"};
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "#fff" : "#262626"};

      @media screen and (max-width: 768px) {
        max-height: 300px;
        padding-bottom: 32px;
      }
      li {
        color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
        text-overflow: ellipsis;
        white-space: nowrap;

        /* Fixed heading styles/heading-reg-07 */
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;

        h6 {
          color: var(--Accessibility-Black, #000);
          /* Fixed heading styles/heading-semi-06 */
          font-family: "IBM Plex Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }
    }
  }
`;
