import styled from "styled-components";

export const CopilotMobile = styled.div`
  max-height: 457px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) =>
    props.theme.colorScheme == "white" ? "white" : "#262626"};
  border: ${(props) =>
    props.theme.colorScheme == "white" ? "1px solid  #e9f1ff" : "none"};
  box-shadow: 0px 2px 2px 0px rgba(33, 36, 44, 0.2);
  padding: 0 16px;

  .message-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 8px 0 25px 0;
    height: 337px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    .message {
      display: flex;
      gap: 0.5rem;
      position: relative;

      .avatar {
        aspect-ratio: 1;
        height: 32px;
        width: 32px;
        /* background: linear-gradient(90deg, #4a72ff 0%, #b56aff 100%); */
        background: linear-gradient(96deg, #2570fe 21.77%, #d0e2ff 129.47%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;

        svg {
          height: 13px;
          width: 13px;
          fill: white;
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        max-width: 70%;
        width: 100%;
        border-radius: 10px;

        .content {
          padding: 0.5rem;

          &:has(.loader) {
            width: max-content !important;
          }
        }

        .loader {
          width: 45px;
          height: 18px;
          --_g: no-repeat
            radial-gradient(circle closest-side, #bcbcbc 90%, #0000);
          background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
          background-size: calc(100% / 3) 50%;
          animation: l3 1s infinite linear;
        }

        .content:has(.loader) {
          height: 31px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .content {
        padding: 1rem;
        width: auto;
        max-width: 100%;
        border-radius: 10px;
        word-wrap: break-word;

        p {
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }

        a {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#0F62FE" : "white"};
          text-decoration: underline;
        }
      }

      &.right {
        flex-direction: row-reverse;

        .content-wrapper {
          align-items: flex-end;
        }

        .content {
          background: ${(props) =>
            props.theme.colorScheme === "white" ? "#474747" : "#f4f4f4"};
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "white" : "#262626"};
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
          min-width: 215px;
        }
      }

      &.left {
        .content {
          /* border: 1px solid #e0e0e0; */
          background: ${(props) =>
            props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#161616" : "#f4f4f4"};

          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
          span {
            color: ${(props) =>
              props.theme.colorScheme == "white"
                ? "var(--text-text-primary, var(--Text-text-primary, #161616))"
                : "white"};
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }

    .content.error {
      border: 1px solid red !important;
    }
  }

  h5 {
    font-family: IBM Plex Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--link-link-primary, var(--Default-Blue-60, #2570fe));
    text-align: center;
  }

  .icon {
    display: flex;
    align-items: start;
    justify-content: center;
    margin-top: 0px;
    color: #0f62fe !important;
  }

  h2 {
    color: var(
      --background-background-brand,
      var(--Background-background-brand, #0f62fe)
    );
    font-family: IBM Plex Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  .form-container-mobile {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #8d8d8d;
    margin-top: 25px;
    .cds--btn--primary {
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"} !important;
      height: 48px;
    }

    .cds--text-input {
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"} !important;
      height: 48px;
      border-block-end: none;
    }
    svg {
      color: #8d8d8d;
      height: 16px;
      width: 16px;
      fill: #8d8d8d;
      margin-top: 5px;
    }
  }
`;

export const CopilotWrapper = styled.div`
  background: url(${process.env.REACT_APP_IMAGE_URL}/copilotWrapper.png),
    var(
      --cta-cta-background-04,
      linear-gradient(133deg, #164398 15.51%, #000 77.75%)
    );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  overflow: hidden;
  padding: ${(props) => (props?.isLoading ? "2px" : "0px")};
  box-shadow: ${(props) =>
    props.isLoading ? "none" : "  0px 2px 2px 0px rgba(33, 36, 44, 0.2) "};

  &::before {
    content: "";
    position: absolute;
    background: ${(props) =>
      props?.isLoading
        ? `linear-gradient(90deg, #2570fe, #fff),
      linear-gradient(-90deg, #2570fe, #fff)`
        : "none"};
    height: calc(100% + 2px * 4);
    width: calc(100% + 2px * 4);
    top: 0;
    left: 0;
    background-size: 120%;
    animation: borderanimate 4s ease infinite;
  }

  @keyframes borderanimate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media screen and (max-width: 768px) {
    background: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#262626"};

    background: url(${process.env.REACT_APP_IMAGE_URL}/copilotmobilewarppe.png),
      var(
        --cta-cta-background-04,
        linear-gradient(133deg, #164398 15.51%, #000 77.75%)
      );
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0 !important;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      background: ${(props) =>
        props?.isLoading
          ? `linear-gradient(90deg, #2570fe, #fff),
      linear-gradient(-90deg, #2570fe, #fff)`
          : "none"};
      height: calc(100% + 2px * 4);
      width: calc(100% + 2px * 4);
      top: 0;
      left: 0;
      background-size: 120%;
      animation: borderanimate 2s ease infinite;
      z-index: -1;
    }

    @keyframes borderanimate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const CopilotContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media screen and (max-width: 768px) {
    padding: 4px 1rem;
  }

  .main_div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button_wrapper {
      padding-top: 29px;
    }

    @media screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.25rem;
      width: 100%;
      padding: 16px 0;
      padding-top: 32px;

      .button_wrapper {
        padding-top: 0;
        margin-left: auto;
      }

      h2 {
        padding-bottom: 0;
      }

      button {
        max-width: max-content !important;
      }
    }
  }

  .ellipse {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(0deg, #393939 0%, #393939 100%), #e0e0e0;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  h2 {
    color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "white"};
    font-family: "IBM Plex Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    padding-bottom: 12px;
  }

  p {
    color: var(--Text-text-on-color, #161616);
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
    padding-inline: 19px;
  }
`;

export const CopilotChat = styled.div`
  gap: 0.5rem;
  background: ${(props) =>
    props.theme.colorScheme == "white" ? "white" : "#262626"};
  display: grid;
  grid-template-rows: 597px 66px;
  position: relative;
  border: ${(props) =>
    (props.isLoading && !props.isDarkTheme) ||
    (props.isLoading && props.isDarkTheme)
      ? " unset "
      : !props.isLoading && props.isDarkTheme
      ? "none"
      : "1px solid var(--cta-cta-background-01, #e9f1ff) "};
  box-shadow: 0px 2px 2px 0px rgba(33, 36, 44, 0.2);

  &:has(.icon_2) {
    grid-template-rows: 25px 572px 66px;
  }

  &.mobile-view.small-view {
    /* position: relative; */
    height: 100px !important;
    min-height: 100px !important;
    grid-template-rows: 25px 66px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }

  .icon_2 {
    display: flex;
    align-items: start;
    justify-content: center;

    &:has(.chevron-up) {
      padding-top: 10px;
    }

    &:has(.chevron-down) {
      padding-bottom: 10px;
    }

    svg,
    path {
      fill: #0f62fe !important;
    }
  }

  .message-list {
    /* height: inherit; */
    padding: 16px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 768px) {
      height: 256px;
      overflow-y: scroll;
    }

    .message {
      display: flex;
      gap: 0.5rem;
      position: relative;

      .avatar {
        aspect-ratio: 1;
        height: 32px;
        width: 32px;
        /* background: linear-gradient(90deg, #4a72ff 0%, #b56aff 100%); */
        background: linear-gradient(96deg, #2570fe 21.77%, #d0e2ff 129.47%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;

        svg {
          height: 13px;
          width: 13px;
          fill: white;
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        max-width: 70%;
        width: 100%;
        padding: 0px 12px;
        border-radius: 10px;

        .content {
          padding: 0.5rem;

          &:has(.loader) {
            width: max-content !important;
          }
        }

        .loader {
          width: 45px;
          height: 18px;
          --_g: no-repeat
            radial-gradient(circle closest-side, #bcbcbc 90%, #0000);
          background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
          background-size: calc(100% / 3) 50%;
          animation: l3 1s infinite linear;
          /* position: absolute;
          top: 0px; */
        }

        .content:has(.loader) {
          /* width: 90px; */
          height: 31px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .content {
        padding: 1rem;
        width: auto;
        max-width: 100%;
        border-radius: 10px;
        word-wrap: break-word;

        p {
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }

        a {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "black" : "white"};
          text-decoration: underline;
        }
      }

      &.right {
        flex-direction: row-reverse;

        .content-wrapper {
          align-items: flex-end;
        }

        .content {
          /* border: 1px solid #e0e0e0; */
          background: ${(props) =>
            props.theme.colorScheme === "white" ? "#474747" : "#f4f4f4"};
          width: 215px;

          color: ${(props) =>
            props.theme.colorScheme === "white" ? "white" : "#262626"};
        }
      }

      &.left {
        .content {
          /* border: 1px solid #e0e0e0; */
          background: ${(props) =>
            props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#161616" : "#f4f4f4"};

          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
          span {
            color: ${(props) =>
              props.theme.colorScheme == "white"
                ? "var(--text-text-primary, var(--Text-text-primary, #161616))"
                : "white"};
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }

    .content.error {
      border: 1px solid red !important;
    }
  }

  .form-container {
    display: flex;
    position: relative;
    margin: 0px 1rem 1rem 1rem;
    border-bottom: 1px solid #8d8d8d;
    height: 50px;

    @media screen and (max-width: 768px) {
      padding-top: 0;
    }

    .cds--btn--primary {
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
      height: 48px;
    }

    .cds--text-input {
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"} !important;
      height: 48px;
      border-block-end: none;
    }

    svg {
      color: #8d8d8d;
      height: 16px;
      width: 16px;
      fill: #8d8d8d;
      margin-top: 5px;
    }

    @keyframes l3 {
      20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
      }
      40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
      }
      60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
      }
      80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
      }
    }
  }
`;
