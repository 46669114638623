import { InteractionStatus } from "@azure/msal-browser";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { GlobalTheme, Loading, Theme } from "@carbon/react";
import { GlobalContext } from "ContextApi/GlobalContext";
import {
  Useraccesstoken,
  countFetch,
  handlePageChangedata,
  user_signup,
} from "Globaldata";
import { datareducer, intitialState } from "Reducer/Reducer";
import { loginRequest, msalConfig, silentRequest } from "authConfig";
import axios from "axios";
import { ThemeProvider } from "components/ThemeProvider";
import Header from "components/header";
import Layout from "components/layout";
import FullScreenModal from "components/modal/fullScreenModal";
import ThemeModal from "components/modal/themeModal";
import { getToken } from "firebase/messaging";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { initWebchat } from "redux/reducers/actions/initWebChat";
import { getAllticketData } from "redux/slices/Alltickets/allticketSlice";
import {
  getAllNotifications,
  getDisturbData,
  getUnseenNotifications,
} from "redux/slices/Notifications/NotificationsSlice";
import { GetArticals } from "redux/slices/RecommendArticals/RecommendSlice";
import { getSalesForceData } from "redux/slices/SalesForce/SalesForceSlice";
import { getExistingSubcriptions } from "redux/slices/Subscriptions/SubscriptionsSlice";
import { getSupportTickets } from "redux/slices/Support/SupportSlice";
import { getalluserData } from "redux/slices/UserSlice/UserAlldataSlice";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { initLogger } from "utils/logger";
import { privateRoutes } from "utils/routes/privateRoutes";
import { getFirebaseMessaging } from "./firebase";
import MobileHeaderNav from "components/sidenav/mobileNav/mobileHeaderNav";
import { useThemeDetector } from "isdarkthemFavicon";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./App.scss";
import { GetFilterOptions } from "redux/slices/TraningGuidens/TrainingGuidensSlice";
import socket from "./socket";

export const Contextprovider = createContext();

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const context = useContext(GlobalContext);
  const isAuthenticated = useIsAuthenticated();
  const reduxDispatch = useDispatch();
  const { data } = useSelector((state) => state.user.data);
  const [useralldata, dispatch] = useReducer(datareducer, intitialState);
  const [authorizaion, setauthorizaion] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading && account) {
      reduxDispatch(getalluserData(account?.idTokenClaims?.sub));
    }
  }, [loading, account]);

  useEffect(() => {
    reduxDispatch(GetFilterOptions());
  }, []);

  useEffect(() => {
    if (data) {
      dispatch({
        type: "USER_SIGNIN_LOGS",
        payload: {
          userid: data?.azure_id,
          fullname: `${data?.name}${" "}${data?.last_name}`,
          email: data?.email,
          phone: data?.phoneNumber,
          country: data?.country,
          address: data?.address,
          city: data?.city,
          state: data?.state,
          postalCode: data?.postalCode,
          companyName: data?.company_name,
          AccountId: data?.account_id,
          ProfileImage: data?.profile_img,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (loading) return;
    reduxDispatch(getDisturbData());
    // reduxDispatch(getAllticketData());
    reduxDispatch(getSalesForceData());
    reduxDispatch(getUnseenNotifications());
    reduxDispatch(getAllNotifications());
    reduxDispatch(
      getSupportTickets({
        email: account?.idTokenClaims?.email,
        pageNumber: 1,
      })
    );
  }, [loading]);

  useEffect(() => {
    try {
      if (!isAuthenticated && inProgress === InteractionStatus.None) {
        instance.loginRedirect(loginRequest);
      } else if (isAuthenticated && accounts && accounts.length > 0) {
        instance.acquireTokenSilent({
          ...silentRequest,
          account: accounts[0],
        });
      }
    } catch (error) {}
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    getFirebaseMessaging().then((messaging) => {
      if (!messaging) return;

      getToken(messaging, {
        vapidKey:
          "BDZ82di-xS1O1kApecoWhmOvFFR5s3Ay82VXxj3JcSOC8CX92LPvs0wv31EDhMSj59yUCiRbffT7m4AieWotWLA",
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("notification_token", currentToken);
            // axios
            //   .get(
            //     `http://localhost:8000/api/all_notification?currentoken=${currentToken}`
            //   )
            //   .then((res) => {});
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    });
  }, []);

  useEffect(() => {
    if (!account) return;

    const urlParams = new URLSearchParams(window.location.search);

    initLogger("info");

    initWebchat({
      serverUrl: "https://api.uvation.com/notify/api/public",
      deploymentKey: urlParams.get("deploymentKey") || "",
      region: urlParams.get("region") || "US1",
      theme: {
        isLight: true,
      },
    });

    // dispatch({ type: "SOCKETDATA_SINGEL_SIGNOUT", payload: socket });

    reduxDispatch(getExistingSubcriptions(account?.idTokenClaims?.sub));
  }, [account]);

  try {
    socket?.on("logoutfromcurrentsite", () => {
      sessionStorage.clear();
      instance.logoutRedirect(msalConfig.auth.postLogoutRedirectUri);
    });
    socket?.on("changeCurrentTheme", (e) => {
      context.setGlobalTheme(e.theme);
    });
  } catch (error) {}

  useEffect(() => {
    if (!loading) {
      Useraccesstoken(dispatch);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && useralldata?.userinfo?.email) {
      countFetch(useralldata, dispatch);
    }
  }, [
    useralldata?.TablePagination.per_page,
    useralldata?.TablePagination.page,
    useralldata?.TablePagination.widget_page,
    useralldata?.TablePagination.widget_per_page,
    useralldata?.Fillter.applyFilter,
    useralldata?.Fillter.search,
    useralldata?.userinfo?.email,
    useralldata?.Paginationloading,
    loading,
  ]);

  useEffect(() => {
    if (!loading && account) {
      user_signup(accounts, account);
    }
  }, [account, loading]);

  useEffect(() => {
    if (account && !loading) {
      handlePageChangedata(account, dispatch);
    }
  }, [account, loading]);

  useEffect(() => {
    if (!account?.idTokenClaims?.sub) return;

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/authToken`, {
        azure_id: account?.idTokenClaims?.sub,
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          localStorage.setItem("authtoken", res?.data?.authToken);
          setauthorizaion(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [account?.idTokenClaims?.sub]);

  useEffect(() => {
    if (context?.globalTheme === "g100") {
      document.body.classList.add("dark");
      localStorage.setItem("theme", "g100");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("theme", "white");
    }
  }, [context?.globalTheme]);

  if (context && context.socketdata) {
    context.socketdata.on("changeCurrentTheme", (e) => {
      context.setGlobalTheme(e.theme);
    });
  }

  return (
    <ThemeProvider>
      {!loading ? (
        <AuthenticatedTemplate>
          <Helmet>
            <link
              rel="shortcut icon"
              href={
                context?.globalTheme == "g100"
                  ? "https://cdn.uvation.com/marketing/public/logo.svg"
                  : "https://cdn.uvation.com/marketing/public/favicon.ico"
              }
            />
          </Helmet>
          <Contextprovider.Provider value={{ useralldata, dispatch }}>
            {/* <GlobalProvider> */}
            <GlobalTheme theme={context?.globalTheme}>
              <Theme theme={context?.globalTheme}>
                <SCThemeProvider theme={{ colorScheme: context?.globalTheme }}>
                  <BrowserRouter>
                    <Header />
                    <ToastContainer />
                    <ScrollToTop />
                    <MobileHeaderNav />
                    <ThemeModal />
                    <FullScreenModal />

                    <Routes>
                      <Route
                        element={
                          <Layout>
                            <Outlet />
                          </Layout>
                        }
                      >
                        {privateRoutes.map((privateRouteDetail) => {
                          return (
                            <Route
                              exact
                              path="/"
                              element={<Outlet />}
                              key={privateRouteDetail.id}
                            >
                              <Route
                                exact
                                path={privateRouteDetail.path}
                                element={<privateRouteDetail.component />}
                              />
                            </Route>
                          );
                        })}
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </SCThemeProvider>
              </Theme>
            </GlobalTheme>
            {/* </GlobalProvider> */}
          </Contextprovider.Provider>
        </AuthenticatedTemplate>
      ) : (
        <UnauthenticatedTemplate>
          <Loading active withOverlay />
        </UnauthenticatedTemplate>
      )}
    </ThemeProvider>
  );
};

export default App;
