import styled from "styled-components";
const TrainingSingleWrapper = styled.main`
  @media screen and (max-width: 768px) {
    .dropdownsticky {
      position: -webkit-sticky;
      position: sticky;
      top: 48px;
      z-index: 99;
    }
  }

  .dropdown {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
      background-color: #52525210;
    }

    .cds--list-box__label {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
      .icon_div {
        svg {
          fill: ${(props) =>
            props.theme.colorScheme == "white" ? "black" : "white"};
        }
      }
    }

    .cds--dropdown__wrapper {
      background-color: white;

      .cds--dropdown {
        .cds--list-box__menu-icon {
          z-index: 20;
        }

        .mobile_slink_dropdown {
          background-color: transparent;
          color: var(
            --text-text-secondary,
            var(--Text-text-secondary, #525252)
          );
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }

  .header {
    padding: 32px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 768px) {
      padding: 32px 16px;
      flex-direction: column;
    }

    ul {
      display: flex;
      gap: 8px;
      min-width: 180px;
      @media screen and (max-width: 768px) {
        padding: 18px 0px 0 0px;
      }
      li {
        color: var(--Text-text-secondary, #525252);
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.16px;
        list-style: disc;
        padding-right: 12px;
      }
    }

    .Breadcrumb {
      .cds--breadcrumb {
        .cds--link {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#2570FE" : "#77A6FF"};
        }
      }
    }

    @media screen and (max-width: 768px) {
      nav {
        z-index: 5;
        .cds--breadcrumb {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          z-index: 10;
        }
      }
    }

    h4 {
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1599999964237213px;
      text-align: left;
      text-transform: uppercase;
      z-index: 1;
    }
    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0px;
      font-style: normal;
      z-index: 1;
      text-align: left;
    }
    &::after {
      content: url(${process.env.REACT_APP_IMAGE_URL}/headerbackground.png);
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 0;

      @media screen and (max-width: 768px) {
        content: url(${process.env
          .REACT_APP_IMAGE_URL}/trainingSingleMobile.png);
        top: 0;
      }
    }
    .bottom-line {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        background: #d9d9d9;
      }
    }
  }
  .content {
    padding: 2.5rem;

    @media screen and (max-width: 768px) {
      padding: 32px 16px;
    }
    .scroll-spy-side-nav-col {
      .filter_data {
        display: flex;
        flex-direction: column;
        box-shadow: 3px 0px 0px 0px #c6c6c6 inset;
        position: sticky;
        top: 0;
        a {
          color: #8d8d8d;
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.16px;
          cursor: pointer;
        }

        .spy-box-content {
          display: flex;
          gap: 8px;
        }

        .active {
          box-shadow: 3px 0px 0px 0px #0f62fe inset;
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#161616" : "#fff"};
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.16px;
        }
      }

      /* .scroll-spy-link-box {
        display: flex;
        flex-direction: column;

        a {
          color: #2570fe;
          cursor: pointer;
          padding: 12px 0;
          border-left: 2px solid var(--border-border-subtle-01, #c6c6c6);
          background-blend-mode: multiply;
        }
        .active {
          display: block;
          box-shadow: 3px 0px 0px 0px #0f62fe inset;
          background-blend-mode: multiply;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 10%;
          display: none;
        }

        .spy-box-content {
          display: flex;
          padding: 7px 16px;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          overflow: hidden;
          color: var(
            --text-text-secondary,
            var(--text-text-secondary, #161616)
          );
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0.16px;
        }
      } */
    }
    .code-snippet-container {
      @media screen and (min-width: 769px) and (max-width: 1024px) {
        margin-top: 1.5rem;
      }
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        padding-bottom: 42px;

        h5 {
          font-family: IBM Plex Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-bottom: 8px;
        }
        .description {
          display: flex;
          flex-direction: column;
          gap: 8px;
          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
            letter-spacing: -0.014px;
          }

          img {
            max-height: 70%;
            max-width: 70%;

            @media screen and (max-width: 768px) {
              max-height: 100%;
              max-width: 100%;
            }
          }
        }
        ul {
          list-style: disc;
          li {
            list-style-position: inside;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 214.286% */
            letter-spacing: -0.014px;
          }
        }
        .code-box {
          padding: 4rem;
          .cds--snippet {
            width: 100%;
            max-width: 100%;
            .cds--snippet-container {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
export default TrainingSingleWrapper;
