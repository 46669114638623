import styled from "styled-components";

export const TicketDetailsWrapper = styled.section`
  background-color: #f4f4f4;
  min-height: calc(100vh - 3rem);
  margin-top: 3rem;
  background: url(${process.env.REACT_APP_IMAGE_URL}/requestbackgound.png);
  background-position: top right 0;
  background-size: contain;
  background-repeat: no-repeat;

  .cds--list-box__field {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "" : "#393939"};
  }

  .cds--breadcrumb-item {
    z-index: 20;
  }

  .cds--text-input {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#393939"};
  }

  .cds--text-area {
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "white" : "#393939"};
  }

  .ticket_Wrapper {
    position: relative;
    &::after {
      @media screen and (max-width: 479px) {
        content: url(${process.env.REACT_APP_IMAGE_URL}/ticketinfomobile.png);
      }
    }
  }

  .ticketsinglePage {
    padding: 20px;
  }
  .ticketIndicator {
    padding: 20px;
  }

  @media screen and (max-width: 768px) {
    .case_type {
      margin-top: 5%;
    }
  }

  .main_headingdiv {
    padding: 32px 0;
    z-index: 10;

    @media screen and (max-width: 768px) {
      padding: 32px 0 0 0;
    }

    .progress_indication {
      display: flex;
      justify-content: end;
      /* scrollbarwidth: none; */

      .cds--progress-step--incomplete {
        button {
          svg {
            fill: ${(props) =>
              props.theme.colorScheme === "white" ? "#a8a8a8" : "#6f6f6f"};
          }
          p {
            color: ${(props) =>
              props.theme.colorScheme === "white"
                ? "#a8a8a8 !important"
                : "#6f6f6f !important"};
            font-family: "IBM Plex Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.32px;
          }
        }
      }

      /* &::-webkit-scrollbar: {
        display: none;
      } */

      ::-webkit-scrollbar {
        width: 0;
      }

      .cds--progress--space-equal .cds--progress-step {
        min-inline-size: 9.5rem !important;
      }

      .cds--progress--space-equal .cds--progress-text .cds--progress-label {
        color: ${(props) =>
          props.theme.colorScheme === "white" ? "#161616" : "#fff"};
        z-index: 10;
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }

      .cds--progress-optional {
        color: ${(props) =>
          props.theme.colorScheme === "white" ? "#161616" : "#fff"};
        z-index: 10;
        font-family: "IBM Plex Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.32px;
      }

      .cds--progress-step--current .cds--progress-line {
        background-color: ${(props) =>
          props.theme.colorScheme === "white" ? "#2570FE" : "#2570FE"};
        z-index: 10;
      }
      .cds--progress-step svg {
        fill: #2570fe;
      }

      @media screen and (max-width: 768px) {
        margin-top: 32px;
        justify-content: flex-start;

        .cds--progress {
          width: 95vw;
          min-height: 80px;
          overflow-x: scroll;

          .cds--progress-step {
            /* margin-right: 8%; */
            /* padding-right: 10px; */
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .cds--breadcrumb {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        line-height: 25px;
      }
    }
  }
  .main_heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      color: var(--link-link-primary, var(--link-link-primary, #0f62fe));
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
      span {
        color: var(--text-text-primary, var(--text-text-primary, #161616));
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
    }
    h1 {
      /* Fixed heading styles/heading-05 */
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-style: normal;
      line-height: 27px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;

export const TicketchatWrapper = styled.section`
  /* .cds--text-input__field-outer-wrapper {
    @media screen and (max-width: 768px) {
      inline-size: 98%;
    }
  }

  .cds--dropdown {
    @media screen and (max-width: 768px) {
      inline-size: 98%;
    }
  } */

  .sub_div {
    border-top: ${(props) =>
      props.theme.colorScheme == "white"
        ? " 1px solid #c6c6c6"
        : "1px solid #474747"};

    .top_container {
      @media screen and (max-width: 768px) {
        .cds--row {
          flex-wrap: wrap-reverse;
        }

        .cds--col-lg-11 {
          border-top: ${(props) =>
            props.theme.colorScheme == "white"
              ? "1px solid #e0e0e0"
              : "1px solid #474747"};
          margin-top: 40px;
        }
      }
    }
  }

  .support_chatdiv-main {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat_heading {
    h4 {
      font-family: "IBM Plex Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 16px;
    }
  }

  .technical_problem {
    p {
      color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }

  .message_length {
    position: absolute;
    top: 2px;
    color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
    font-family: "IBM Plex Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }

  .case_div::-webkit-scrollbar {
    display: none;
  }

  .container_small {
    .case_div {
      min-height: 700px;
      max-height: 700px;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    /* border-left: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid #c6c6c6"
        : "1px solid #474747"}; */
    /* border-right: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid #c6c6c6"
        : "1px solid #474747"}; */

    @media screen and (max-width: 768px) {
      border: none;
    }
  }

  .container {
    @media screen and (max-width: 768px) {
      padding-top: 0;
    }
  }

  .opened_attachment {
    padding-inline: 24px;
    /* padding-top: 32px; */

    @media screen and (max-width: 768px) {
      padding: 0 0px 0 8px;
    }

    h6 {
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
    }

    .fileuploder_item {
      padding-top: 16px;

      .cds--file__state-container .cds--file-close {
        visibility: hidden;
      }

      .cds--file__selected-file {
        display: grid;
        align-items: center;
        background-color: ${(props) =>
          props.theme.colorScheme == "white" ? "white" : "#393939"};
        /* background-color: var(--cds-layer); */
        gap: 0.75rem 0;
        grid-auto-rows: auto;
        grid-template-columns: 1fr auto;
        margin-block-end: 0.5rem;
        max-inline-size: 33rem;
        word-break: break-word;
        padding: 15px 16px;
      }
    }

    .tag_container {
      padding: 16px 0;
      h6 {
        padding-bottom: 8px;
        font-family: "IBM Plex Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.32px;
      }

      .cds--tag {
        box-shadow: 0 0 0 1px
          ${(props) =>
            props.theme.colorScheme === "white" ? "#C6C6C6" : "#c6c6c6"};

        background-color: ${(props) =>
          props.theme.colorScheme === "white" ? "#fff" : "#161616"};

        span {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#8D8D8D" : "#c6c6c6"};
        }
      }
    }
  }

  .attachment {
    /* margin-top: 32px; */
    margin-bottom: 34px;
  }

  .support_chatdiv-main {
    min-height: 535px;
    max-height: 535px;
    overflow-y: scroll;

    .support_chatdiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: 24px;
      align-items: flex-start;
      gap: 16px;
      border: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid #e0e0e0"
          : "1px solid #474747"};
    }
  }
  .support_div {
    padding: 24px;
    display: flex;

    .suport_temheading {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      border-radius: 10px;
      padding: 12px;
      border: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid #e0e0e0"
          : "1px solid #474747"};

      h4 {
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.16px;
      }
      .message {
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
      }
      p {
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
      }
    }
    .headphon_icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      margin-top: 20px;
      background: linear-gradient(180deg, #5e96ff 0%, #0f61fd 100%), #e0e0e0;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: #fff;
      }
    }
    .user_icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      background-color: #393939;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        fill: #fff;
      }
    }
    span {
      color: var(--text-text-placeholder, #a8a8a8);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.32px;
    }
  }
  .chat_description {
    padding: 32px 28px 16px 28px;

    @media screen and (max-width: 768px) {
      padding: 32px 0px 32px 0px;
    }

    .btn-data {
      display: flex;
      justify-content: end;
      gap: 20px;
      align-items: center;
      .uploadefile {
        .cds--btn--primary {
          border: 1px dashed #0f61fd;
          background-color: unset;
          color: var(--cds-text-on-color, #ffffff);
          padding: 14px;
          min-width: 170px;
        }
        .cds--btn--primary:focus {
          box-shadow: unset;
        }
      }
    }
  }
  .status_page {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    gap: 24px;
    border-bottom: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid #e0e0e0"
        : "1px solid #474747"};

    @media screen and (max-width: 768px) {
      padding: 32px 16px;

      .cds--popover-container {
        margin-left: -10px;
      }
    }

    h5 {
      color: var(--Text-text-secondary, #525252);
      font-family: "IBM Plex Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.32px;
    }
    p {
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.16px;
    }
    h6 {
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#525252" : "#c6c6c6"};
      font-family: IBM Plex Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.32px;
    }
    .casetype {
      span {
        color: ${(props) =>
          props.theme.colorScheme === "white" ? "#2570FE" : "#77A6fe"};
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .cds--copy-btn {
          background-color: unset;
          svg {
            fill: ${(props) =>
              props.theme.colorScheme === "white" ? "#2570FE" : "#77A6fe"};
            background-color: unset;
          }
        }
      }
    }

    .span_tag {
      padding: 10px;
      border-radius: 1000px;
      background-color: #d0e2ff;
      width: 80px;
      color: var(--link-link-primary, #0f62fe);
      text-align: center;
      @media screen and (max-width: 768px) {
        padding: 8px;
        width: 60px;
      }
    }
    .status_open {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 200px;
    }
    .attachment {
      h6 {
        color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
      h5 {
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
      .fileuploder_item {
        margin-top: 20px;
        span {
          max-width: 100%;
        }
      }
    }
  }
  .textinput {
    position: relative;
    display: flex;
  }

  .edit-button {
    position: absolute;
    top: 65%;
    right: 0;
    transform: translateY(-50%);
  }

  .error {
    color: var(--cds-text-error, #da1e28);
    font-size: 12px;
  }

  .file-uploader-preview-container {
    width: 100%;
    display: flex;

    .file_name {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#161616" : "white"};
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
      max-width: 215px;
    }

    .percentage {
      text-align: right;
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#161616" : "white"};
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }

    .loader-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 4px;
      background-color: #c6c6c6;

      .loader {
        background-color: #2570fe;
        height: 4px;
      }
    }
  }

  .status_page_first {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 0px 76px 0px;
    border-right: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid #e0e0e0"
        : "1px solid #474747"};
    border-bottom: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid #e0e0e0"
        : "1px solid #474747"};

    @media screen and (max-width: 768px) {
      padding: 0px 0px 32px 0px;
      border-right: none;
    }

    p {
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
    h5 {
      color: var(--text-text-helper, var(--Text-text-helper, #6f6f6f));
      font-family: "IBM Plex Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.32px;
    }

    h6 {
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
    }
  }
`;
