import { useAccount, useMsal } from "@azure/msal-react";
import { Button, ButtonSet, HeaderPanel, Toggle } from "@carbon/react";
import { Contextprovider } from "App";
import { GlobalContext } from "ContextApi/GlobalContext";
import axiosInstance from "axiosintance";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisturbData,
  getUnseenNotifications,
} from "redux/slices/Notifications/NotificationsSlice";
import NotificationWrapper from "./styles";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Link } from "react-router-dom/dist";

const Notification = ({ open }) => {
  const { accounts } = useMsal();
  const Navigate = useNavigate();
  const account = useAccount(accounts[0] || {});
  const reduxDispatch = useDispatch();
  const { useralldata, dispatch } = useContext(Contextprovider);
  const { toggle, settoggle } = useContext(GlobalContext);

  const { unseenNotifications, allNotifications, Notificationstop } =
    useSelector((state) => state.notifications);

  const shownotification = useSelector(
    (state) => state.notifications.allNotifications.data
  );

  const notificationcheck = (notification) => {
    axiosInstance
      .post(`${process.env.REACT_APP_NOTIFICATION_URL}/read`, {
        userid: account?.idTokenClaims?.sub,
        notification_id: notification?.id,
      })
      .then(() => {
        reduxDispatch(getUnseenNotifications());
      });
  };

  async function dismissAllNotifications() {
    axiosInstance
      .post(`${process.env.REACT_APP_NOTIFICATION_URL}/dismiss_all`, {
        userid: account?.idTokenClaims?.sub,
      })
      .then(() => {
        reduxDispatch(getUnseenNotifications());
      });

    settoggle({ ...toggle, Notification: !toggle.Notification });
  }

  if (!open) return null;

  const handleToggledata = (e) => {
    axiosInstance
      .post(`${process.env.REACT_APP_NOTIFICATION_URL}/updateDND`, {
        userid: useralldata?.userinfo?.userid,
        status: e,
      })
      .then((res) => {
        reduxDispatch(getDisturbData());
      });
  };

  return (
    <NotificationWrapper>
      <HeaderPanel
        aria-label="Account Panel"
        className="account_panel"
        addFocusListeners={false}
        expanded={open}
      >
        <div className="header">
          <h6>Notifications</h6>
          <div className="switch">
            <span>Do Not Disturb</span>
            <Toggle
              size="md"
              defaultToggled={Notificationstop?.data?.status}
              labelA=""
              labelB=""
              id="toggle-1"
              onToggle={(e) => handleToggledata(e)}
            />
          </div>
        </div>

        <div className="box">
          <ul>
            {unseenNotifications?.data?.map((notification) => (
              <>
                {notification?.zendesk_id ? (
                  <li
                    key={notification.id}
                    className="cursor-pointer"
                    onClick={() => notificationcheck(notification)}
                  >
                    <div className="message ">
                      {notification?.zendesk_id
                        ? `${notification?.message} -
                              ${notification?.zendesk_id}`
                        : notification?.message}
                    </div>
                    <div className="date">
                      {moment(notification?.notification_time).format(
                        "MM/DD/YY h:mm A "
                      )}
                    </div>
                  </li>
                ) : null}
              </>
            ))}
          </ul>
        </div>

        <div>
          <ButtonSet className="viewAll_btn">
            <Button size="md" as={Link} to="/notification">
              View All
            </Button>
            <Button
              style={{ backgroundColor: "#474747" }}
              size="md"
              kind="secondary"
              onClick={dismissAllNotifications}
            >
              Mark As Read
            </Button>
          </ButtonSet>
        </div>
      </HeaderPanel>
    </NotificationWrapper>
  );
};

export default Notification;
